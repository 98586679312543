import FormHelperText from '@mui/material/FormHelperText';
import MaterialLink from '@mui/material/Link';
import { makeStyles } from '@mui/styles';
import { loadStripe } from '@stripe/stripe-js';
import get from 'lodash/get';
import queryString from 'query-string';
import { useEffect, useState } from 'react';

import { withRoot } from '../../components/Root';
import { Spinner } from '../../components/Spinner';
import { usePaymentConfig } from '../../hooks/payments';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        padding: '2rem!important',
        [theme.breakpoints.up('lg')]: {
            fontSize: '1.8rem!important',
            padding: '6.4rem!important',
            lineHeight: '3.2rem!important',
        },
    },
    title: {
        color: '#FFF',
        lineHeight: '1.625!important',
        fontSize: '1.8rem',
        marginBottom: 0,
        marginTop: 0,
        fontWeight: 600,
        paddingBottom: '2rem',
    },
    error: {
        fontSize: '1.6rem!important',
    },
}));

function SubscriptionStatus({ location, history }) {
    const classes = useStyles();

    const search = queryString.parse(location.search);
    const [paymentIntent, setPaymentIntent] = useState(null);
    const [submitError, setSubmitError] = useState('');
    // eslint-disable-next-line camelcase
    const { payment_intent_client_secret } = search;
    const { loaded, loading, config } = usePaymentConfig();

    const apiKey = get(config, 'apiKey');

    useEffect(() => {
        async function getStatus() {
            const stripe = await loadStripe(apiKey);
            const { paymentIntent: pm, error } = await stripe.retrievePaymentIntent(
                payment_intent_client_secret,
            );
            if (error) {
                setSubmitError(error.message);
                return;
            }

            setPaymentIntent(pm);
        }

        // eslint-disable-next-line camelcase
        if (apiKey && payment_intent_client_secret && !paymentIntent) {
            getStatus();
        }
        // eslint-disable-next-line camelcase
    }, [apiKey, payment_intent_client_secret, paymentIntent]);

    useEffect(() => {
        if (paymentIntent) {
            switch (paymentIntent.status) {
                case 'succeeded':
                case 'processing':
                    history.replace(`/checkout-success?order_id=${search.order_id}`);
                    break;

                case 'requires_payment_method':
                    setSubmitError('Payment failed. Please try another payment method.');
                    // Try again button
                    break;

                default:
                    setSubmitError('Something went wrong.');
                    break;
            }
        }
    }, [paymentIntent, search.order_id, history]);

    if (loading || !loaded) {
        return <Spinner />;
    }

    if (!submitError) {
        return null;
    }

    return (
        <div className={classes.root}>
            <div className={classes.title}>Subscription Status</div>

            {Boolean(submitError) && (
                <>
                    <FormHelperText error classes={{ error: classes.error }}>
                        {submitError}
                    </FormHelperText>
                    <MaterialLink href="/">Retry</MaterialLink>
                </>
            )}
        </div>
    );
}

SubscriptionStatus.getInitialProps = async () => {
    await Promise.resolve();
};

SubscriptionStatus.getChunkName = () => {
    return 'SubscriptionStatus';
};

const SubscriptionStatusRoute = withRoot(SubscriptionStatus);

export { SubscriptionStatusRoute as SubscriptionStatus };
