/* eslint-disable no-param-reassign */
import { LoadingButton as Button } from '@mui/lab';
import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import { makeStyles } from '@mui/styles';
import { useFormik } from 'formik';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import * as yup from 'yup';

import { Input } from '../../components/Input';
import { Label } from '../../components/Label';
import { withRoot } from '../../components/Root';
import { Spinner } from '../../components/Spinner';
import { useFragmentContext } from '../../hooks/useFragmentContext';
import { apiAxiosInstance } from '../../utils/axiosInstance';
import { getErrorContent } from '../../utils/errors';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        padding: '2rem!important',
        [theme.breakpoints.up('lg')]: {
            fontSize: '1.8rem!important',
            padding: '6.4rem!important',
            lineHeight: '3.2rem!important',
            gap: '2rem!important',
        },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: '1.4rem!important',
        color: '#FFF',
    },
    title: {
        color: '#FFF',
        lineHeight: '1.625!important',
        fontSize: '1.8rem',
        marginBottom: 0,
        marginTop: 0,
        fontWeight: 600,
        paddingBottom: '2rem',
    },
    subtitle: {
        color: '#FFF',
        // lineHeight: '1.625!important',
        fontSize: '1.5rem',
        marginBottom: 0,
        marginTop: 0,
        fontWeight: 300,
        paddingBottom: '2rem',
    },
    controls: {
        marginBottom: '1.5rem!important',
    },
    input: {
        background: '#222',
    },
    actions: {
        display: 'flex',
        gap: '1rem!important',
        alignItems: 'center',
        flexDirection: 'column',
    },
    btn: {
        paddingLeft: '2.4rem!important',
        paddingRight: '2.4rem!important',
        paddingTop: '1.2rem!important',
        paddingBottom: '1.2rem!important',
        fontSize: '1.6rem!important',
        fontWeight: 600,
        '&:hover': {
            background: theme.palette.secondary.main,
        },
    },
    disabledBtn: {
        backgroundColor: `${theme.palette.secondary.main}!important`,
        color: '#FFF!important',
    },
    error: {
        fontSize: '1.6rem!important',
    },
}));

const CreateSubscription = ({ location, history, checkoutURL }) => {
    const { user } = useFragmentContext();
    const checkoutURLSearchParams = checkoutURL?.split('?')[1];
    const searchParams = checkoutURLSearchParams ? `?${checkoutURLSearchParams}` : location.search;
    const search = queryString.parse(searchParams);
    const classes = useStyles();
    const [submitError, setSubmitError] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const customerEmail = user.email || '';
    const customerName = user.full_name || '';
    const paymentMethodType = search.plan_id ? 'paystack' : 'stripe';
    const formData = {
        orderId: search.order_id,
        productId: search.product_id,
        planId: search.plan_id,
        paymentMethodType,
        customer: {
            email: customerEmail,
            name: customerName,
        },
    };
    const handleAutoSubmit = async () => {
        try {
            const { data } = await apiAxiosInstance.post(
                'payment-gateway/v1/payment-session/create',
                formData,
            );

            history.push(`/subscription/complete`, {
                ...data.paymentSession,
                orderId: formData.orderId,
            });
        } catch (error) {
            setSubmitError(getErrorContent(error));
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (!customerEmail || !customerName) {
            setIsLoading(false);
        } else {
            handleAutoSubmit();
        }
        // This effect need to only run once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const formik = useFormik({
        validationSchema: yup.object().shape({
            customerEmail: yup
                .string()
                .email()
                .required('Please enter your email')
                .typeError('Please enter a valid email address'),
            customerName: yup.string().required('Please enter your name'),
        }),
        validateOnChange: false,
        validateOnBlur: false,
        initialValues: {
            ...formData,
            customerEmail,
            customerName,
        },
        onSubmit: async values => {
            formData.customer.email = values.customerEmail;
            formData.customer.name = values.customerName;

            // delete form field input
            delete values.customerEmail;
            delete values.customerName;
            await fetch('/user/v1/update', {
                method: 'PATCH',
                headers: {
                    'Content-type': 'application/json',
                    'Accept-Encoding': 'application/json',
                },
                body: JSON.stringify({
                    email: formData.customer.email,
                    fullName: formData.customer.name,
                }),
            }).catch(error => {
                setSubmitError(getErrorContent(error));
            });
            await handleAutoSubmit();
        },
    });

    const onChange = (name, value) => {
        formik.handleChange({
            target: {
                name,
                value,
            },
        });
    };

    const { values, errors } = formik;

    return (
        <div className={classes.root}>
            {isLoading ? (
                <Box className={classes.spinnerContainer}>
                    <Spinner />
                </Box>
            ) : (
                <>
                    <div className={classes.title}>Billing Account Information</div>
                    <div className={classes.subtitle}>
                        Your Full Name and Email are required to process external payment
                        successfully
                    </div>
                    <form noValidate onSubmit={formik.handleSubmit} className={classes.form}>
                        <div className={classes.controls}>
                            <Label classes={{ label: classes.label }} value="Your Email" />

                            <Input
                                classes={{ textFieldRoot: classes.input }}
                                variant="outlined"
                                name="customerEmail"
                                value={values.customerEmail}
                                placeholder="Email"
                                type="email"
                                autoComplete="off"
                                fullWidth
                                error={errors.customerEmail}
                                onChange={onChange}
                            />
                        </div>

                        <div className={classes.controls}>
                            <Label classes={{ label: classes.label }} value="Your Full Name" />

                            <Input
                                classes={{ textFieldRoot: classes.input }}
                                variant="outlined"
                                name="customerName"
                                value={values.customerName}
                                placeholder="Enter your full name"
                                type="text"
                                autoComplete="off"
                                fullWidth
                                error={errors.customerName}
                                onChange={onChange}
                            />
                        </div>

                        {Boolean(submitError) && (
                            <div className={classes.controls}>
                                <FormHelperText error classes={{ error: classes.error }}>
                                    {submitError}
                                </FormHelperText>
                            </div>
                        )}

                        <div className={classes.actions}>
                            <Button
                                loading={formik.isSubmitting}
                                disabled={formik.isSubmitting}
                                type="submit"
                                variant="contained"
                                className={classes.btn}
                                classes={{ disabled: classes.disabledBtn }}
                                color="secondary"
                            >
                                Continue
                            </Button>
                        </div>
                    </form>
                </>
            )}
        </div>
    );
};

CreateSubscription.getInitialProps = async () => {
    await Promise.resolve();
};

CreateSubscription.getChunkName = () => {
    return 'CreateSubscription';
};

const CreateSubscriptionRoute = withRoot(CreateSubscription);

export { CreateSubscriptionRoute as CreateSubscription };
