import { async } from '../../utils/async';

// eslint-disable-next-line promise/prefer-await-to-callbacks
export const sessLogin = (params, callback) => {
    async(
        'POST',
        {
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        },
        '/auth/session_login',
        params,
        callback,
    );
};

// eslint-disable-next-line promise/prefer-await-to-callbacks
export const signInUser = (values, callback) => {
    async(
        'POST',
        {
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        },
        '/auth/login',
        values,
        callback,
    );
};
