import { LoadingButton as Button } from '@mui/lab';
import FormHelperText from '@mui/material/FormHelperText';
import { makeStyles } from '@mui/styles';
import { useFormik } from 'formik';
import queryString from 'query-string';
import { useState } from 'react';
import * as yup from 'yup';

import { PhoneNumberInput } from '../../components/PhoneNumberInput';
import { withRoot } from '../../components/Root';
import { Select } from '../../components/Select/Select';
import { Checkout as Layout } from '../../layouts/Checkout';
import { getErrorContent } from '../../utils/errors';
import { createPaymentSession } from '../Checkout/async';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: '1.4rem!important',
        [theme.breakpoints.up('lg')]: {
            fontSize: '2.4rem!important',
            lineHeight: '3.2rem!important',
        },
    },
    actions: {
        display: 'flex',
        gap: '1rem!important',
        marginTop: theme.spacing(4),
        alignItems: 'center',
        flexDirection: 'column',
    },
    controls: {
        marginBottom: theme.spacing(2),
        '& input': {
            fontSize: 12,
        },
    },
    title: {
        fontWeight: 600,
        marginBottom: theme.spacing(2),
    },
    btn: {
        paddingLeft: '2.4rem!important',
        paddingRight: '2.4rem!important',
        paddingTop: '1.2rem!important',
        paddingBottom: '1.2rem!important',
        fontSize: '1.6rem!important',
        fontWeight: 600,
        '&:hover': {
            background: theme.palette.secondary.main,
        },
    },
    disabledBtn: {
        backgroundColor: `${theme.palette.secondary.main}!important`,
        color: '#FFF!important',
    },
    error: {
        fontSize: '1.6rem!important',
    },
    select: {
        padding: theme.spacing(),
        '&:focus': {},
    },
    label: {},
    labelFocused: {},
}));

const defaultState = {
    planId: '',
    phoneNumber: '',
    network: 'MTN',
    promotional: '',
};

const supportedNetworks = [
    { value: 'MTN', label: 'MTN GH' },
    { value: 'Airtel', label: 'AirtelTigo' },
    { value: 'Vodafone', label: 'Vodafone GH' },
];

const MobileMoneyCheckout = ({ location, user, history }) => {
    const classes = useStyles();
    const [submitErrorMessage, setSubmitErrorMessage] = useState(null);

    const search = queryString.parse(location.search);

    const formik = useFormik({
        initialValues: {
            ...defaultState,
            planId: search.plan,
            promotional: search.promotional,

            phoneNumber: user?.phone_number || location.state?.phoneNumber,

            network: user?.network || location.state?.network || defaultState.network,
        },
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: yup.object().shape({
            phoneNumber: yup.string().required('Phone number is required'),
            network: yup.string().required('Network is required'),
        }),
        onSubmit: (values, { setSubmitting }) => {
            // eslint-disable-next-line no-param-reassign
            values.paymentMethodType = 'mobile-money';
            // eslint-disable-next-line promise/prefer-await-to-callbacks
            createPaymentSession(values, (err, response) => {
                setSubmitting(false);

                if (err) {
                    setSubmitErrorMessage(getErrorContent(err));
                    return;
                }

                const query = {
                    order_id: response.data.orderId,
                    requires_confirm: true,
                };

                if (response?.data?.orderId) {
                    history.push(`/checkout-success?${queryString.stringify(query)}`);
                }
            });
        },
    });

    const onChange = (field, value) => {
        formik.handleChange({ target: { name: field, value } });
    };

    const { values, errors, isSubmitting } = formik;

    return (
        <Layout>
            <div className={classes.root}>
                <form onSubmit={formik.handleSubmit} className={classes.content}>
                    <div className={classes.title}>Pay VIA Mobile Money</div>

                    <div className={classes.controls}>
                        <PhoneNumberInput
                            name="phoneNumber"
                            error={errors.phoneNumber}
                            value={values.phoneNumber}
                            placeholder="Enter mobile money number"
                            onChange={onChange}
                            hasCountrySelect={false}
                        />
                    </div>

                    <div className={classes.controls}>
                        <Select
                            value={values.network}
                            onChange={evt => onChange('network', evt.target.value)}
                            fullWidth
                            displayEmpty
                            classes={{
                                selectRoot: classes.select,
                                label: classes.label,
                                labelFocused: classes.labelFocused,
                            }}
                            label="Network"
                            options={supportedNetworks}
                            error={errors.network}
                        />
                    </div>

                    {Boolean(submitErrorMessage) && (
                        <FormHelperText error classes={{ error: classes.error }}>
                            {submitErrorMessage}
                        </FormHelperText>
                    )}

                    <div className={classes.actions}>
                        <Button
                            loading={isSubmitting}
                            disabled={isSubmitting}
                            type="submit"
                            variant="contained"
                            className={classes.btn}
                            classes={{ disabled: classes.disabledBtn }}
                            color="secondary"
                        >
                            Continue
                        </Button>
                    </div>
                </form>
            </div>
        </Layout>
    );
};

MobileMoneyCheckout.getInitialProps = async () => {
    await Promise.resolve();
};

MobileMoneyCheckout.getChunkName = () => {
    return 'MobileMoneyCheckout';
};

const MobileMoneyCheckoutRoute = withRoot(MobileMoneyCheckout);

export { MobileMoneyCheckoutRoute as MobileMoneyCheckout };
