import Modal from '@mui/material/Modal';
import { makeStyles } from '@mui/styles';

import { CreateSubscription } from '../CreateSubscription';

const useStyles = makeStyles(theme => ({
    modalContent: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%', // Adjust width as needed
        padding: theme.spacing(2, 4, 3),
        boxShadow: theme.shadows[5],
        backgroundColor: 'black',
    },
}));

const CheckoutModal = ({ open, onClose, location, history, checkoutURL }) => {
    const classes = useStyles();

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="checkout-modal-title"
            aria-describedby="checkout-modal-description"
        >
            <div className={classes.modalContent}>
                <CreateSubscription
                    location={location}
                    history={history}
                    checkoutURL={checkoutURL}
                />
            </div>
        </Modal>
    );
};

export { CheckoutModal };
