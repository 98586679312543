import { doc, getFirestore, onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';

import { firebaseApp } from '../../firebase-app';

const firestore = getFirestore(firebaseApp);

function useOrder(id?: string): {
    loaded: boolean;
    order: Record<string, unknown> | undefined;
} {
    const [loaded, setLoaded] = useState(false);
    const [order, setOrder] = useState<Record<string, unknown> | undefined>(undefined);

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (id) {
            const orderDoc = doc(firestore, 'orders', id);
            const unsubscribe = onSnapshot(orderDoc, orderSnapshot => {
                setLoaded(true);
                if (orderSnapshot.exists()) {
                    setOrder(orderSnapshot.data());
                }
            });

            // Cleanup the listener when the component unmounts or id changes
            return () => unsubscribe();
        }
    }, [id, loaded]);

    return { loaded, order };
}

export { useOrder };
