import PropTypes from 'prop-types';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input';
import { makeStyles } from '@mui/styles';
import flags from 'react-phone-number-input/flags';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { FormControl } from '@mui/material';

const useStyles = makeStyles(theme => ({
    root: {
        '& svg': {
            color: '#FFF',
            fontSize: '2rem',
        },
    },
    select: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    country: {
        marginLeft: theme.spacing(0.5),
        fontSize: '1.4rem',
        fontWeight: 600,
        color: '#a3a9b2',
    },
    icon: {
        top: 'calc(50% - 10px)',
    },
    formControl: {
        minWidth: 'unset',
    },
}));

const CountrySelect = ({ value, onChange, ...rest }) => {
    const classes = useStyles();

    return (
        <FormControl classes={{ root: classes.formControl }}>
            <Select
                variant="outlined"
                className={classes.root}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...rest}
                value={value}
                classes={{
                    select: classes.select,
                    icon: classes.icon,
                }}
                onChange={event => onChange(event.target.value || undefined)}
            >
                {getCountries().map(country => {
                    const Flag = flags[country];

                    return (
                        <MenuItem selected={false} key={country} value={country}>
                            <Flag width={18} height={18} />
                            <span className={classes.country}>
                                +{getCountryCallingCode(country)} ({country})
                            </span>
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};

CountrySelect.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

CountrySelect.defaultProps = {
    value: undefined,
};

export { CountrySelect };
