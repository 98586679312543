/* eslint-disable react/prop-types */

import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { withStyles } from '@mui/styles';
import omit from 'lodash/omit';
import FormHelperText from '@mui/material/FormHelperText';

import { usePrevious } from '../../hooks/previous';

const styles = theme => ({
    textFieldRoot: {
        padding: 0,
        'label + &': {
            marginTop: theme.spacing(0.5),
        },
    },
    textFieldInput: {
        backgroundColor: theme.palette.common.white,
        '&:disabled': {
            cursor: 'not-allowed',
        },
    },
    textFieldFormLabel: {
        position: 'relative',
        zIndex: 'auto',
        transition: 'z-index .75s steps(1,end)',
    },
    helperText: {
        fontSize: '1rem',
    },
    multilineInput: {
        height: 36,
    },
    errorText: {
        fontSize: '1.35em',
        color: theme.palette.error.main,
        fontWeight: 400,
    },
    errorContainer: {
        marginLeft: 0,
    },
});

const doneTypingInterval = 500;

function TextFieldComponent({ classes, label, error, onChange, delay, ...props }) {
    const typingTimer = useRef();
    const [value, setValue] = useState(props.value);

    let errors = [];
    if (typeof error === 'string' && error !== '') {
        errors.push(error);
    } else if (Array.isArray(error)) {
        // eslint-disable-next-line promise/prefer-await-to-callbacks
        errors = errors.concat(error.map(err => err));
    }

    const hasError = Boolean(errors.length);
    const prev = usePrevious(value);

    const onFieldChange = evt => setValue(evt.target.value);
    const doneTyping = evt => {
        if (value !== prev && typeof onChange === 'function') {
            onChange(props.name, value, evt);
        }
    };

    const onKeyUp = () => {
        clearTimeout(typingTimer.current);
        typingTimer.current = setTimeout(doneTyping, doneTypingInterval);
    };

    return (
        <TextField
            // autoComplete="off"
            label={label}
            onKeyUp={onKeyUp}
            onChange={onFieldChange}
            ref={props.ref}
            inputRef={props.inputRef}
            // eslint-disable-next-line promise/prefer-await-to-callbacks
            helperText={errors.map((err, index) => (
                <FormHelperText
                    classes={{
                        root: classes.errorText,
                        contained: classes.errorContainer,
                    }}
                    style={{ display: 'block' }}
                    component="span"
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    variant="standard"
                    error
                >
                    {err}
                </FormHelperText>
            ))}
            error={hasError}
            FormHelperTextProps={{
                ...props.formHelperTextProps,
                classes: props.formHelperTextProps.classes,
            }}
            InputProps={{
                value,
                classes: props.inputProps.classes || {
                    root: classes.textFieldRoot,
                    input: classes.textFieldInput,
                },
                ...omit(props.inputProps, ['classes']),
            }}
            InputLabelProps={{
                shrink: true,
                className: classes.textFieldFormLabel,
            }}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...omit(props, ['defaultValue', 'inputProps', 'formHelperTextProps'])}
        />
    );
}

TextFieldComponent.propTypes = {
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    inputProps: PropTypes.shape({}),
    formHelperTextProps: PropTypes.shape({}),
};

TextFieldComponent.defaultProps = {
    inputProps: {},
    formHelperTextProps: {},
    error: undefined,
    defaultValue: undefined,
    label: undefined,
};

const Material = withStyles(styles)(TextFieldComponent);

export { Material as TextField };
