import { makeStyles } from '@mui/styles';
import { ReactNode } from 'react';

import { palette } from '../theme/palette';

const useStyles = makeStyles(() => ({
    root: {
        background: palette.background.default,
    },
    center: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%,-50%)',
    },
    progress: {
        color: palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
    },
    dopContent: {
        display: 'flex',
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
        width: '100%',
    },
    main: {
        flexDirection: 'column',
        display: 'flex',
        flexGrow: 1,
        flexShrink: 1,
        minWidth: 0,
    },
}));

const App = ({ children }: { children: ReactNode }): JSX.Element => {
    const classes = useStyles();

    return (
        <div id="app">
            <div className={classes.dopContent}>
                <div className={classes.main}>{children}</div>
            </div>
        </div>
    );
};

export { App };
