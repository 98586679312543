import { reducerRegistry } from '../../store/registry';

const defaultState = {
    config: {
        loading: false,
        loaded: false,
        data: {
            provider: '',
            apiKey: '',
        },
    },
};

function PaymentsReducer(state = defaultState, action) {
    switch (action.type) {
        case 'GET_PAYMENTS_CONFIG_REQUEST':
            return {
                ...state,
                config: { ...state.config, loading: true },
            };
        case 'GET_PAYMENTS_CONFIG':
            return {
                ...state,
                config: {
                    loading: false,
                    loaded: true,
                    data: action.body,
                },
            };
        case 'GET_PAYMENTS_CONFIG_FAILURE':
            console.error(action.error);
            return {
                ...state,
                config: { ...state.config, loading: false, loaded: true },
            };
        default:
            return state;
    }
}

reducerRegistry.register('PaymentsReducer', PaymentsReducer);
