import { LoadingButton as Button } from '@mui/lab';
import FormHelperText from '@mui/material/FormHelperText';
import { makeStyles } from '@mui/styles';
import { useFormik } from 'formik';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import 'react-phone-number-input/style.css';
import * as yup from 'yup';

import { Input } from '../../components/Input';
import { withRoot } from '../../components/Root';
import { Checkout as Layout } from '../../layouts/Checkout';
import { apiAxiosInstance } from '../../utils/axiosInstance';

const useStyles = makeStyles(theme => ({
    '@global': {
        '.PhoneInputCountry': {
            marginRight: 8,
            verticalAlign: 'middle',
            marginTop: -8,
            fontSize: 18,
        },
    },
    login: {
        flex: 1,
        padding: [0, theme.spacing(4)],
        '& form': {
            flex: 1,
        },
    },
    errorText: {
        fontSize: '1.45rem',
        fontWeight: 'normal',
        marginBottom: theme.spacing(),
        textAlign: 'center',
    },
    controls: {
        marginBottom: theme.spacing(),
        '& input': {
            fontSize: 14,
        },
    },
    formActions: {
        marginTop: theme.spacing(3),
        background: 'none',
    },
    btnWrapper: {
        position: 'relative',
    },
    loginButton: {
        textTransform: 'none',
        background: '#ed532d',
        fontSize: 16,
        color: '#FFF',
    },
    btnDisabled: {
        backgroundColor: '#cccccc!important',
        color: '#666666!important',
    },
    error: {
        fontSize: '1.45rem',
        textAlign: 'center',
    },
    phoneInput: {
        marginTop: theme.spacing(2),
        '& input': {
            padding: 12,
            borderRadius: 4,
            // marginTop: -8,
        },
    },
    loginMeta: {
        textAlign: 'center',
        marginTop: theme.spacing(3),
    },
    weightyLink: {
        color: '#FFF',
        fontWeight: 600,
        '&:hover': {
            textDecoration: 'none',
            cursor: 'pointer',
        },
    },
    metaText: {
        color: '#FFF',
    },
}));

const defaultState = {
    confirm_password: '',
    password: '',
};

function ResetPassword({ history, ...props }) {
    const classes = useStyles();
    const [submitMessage, setSubmitMessage] = useState(null);

    const search = queryString.parse(props.location.search);
    const resetToken = search.token;

    const resetPassword = async ({ password }, { setSubmitting }) => {
        setSubmitting(true);
        const payload = {
            newPassword: password,
            resetToken,
        };

        try {
            await apiAxiosInstance.post('/auth/v1/reset-password', payload);
            setSubmitting(false);

            history.push('/password-reset-success');
        } catch (error) {
            setSubmitMessage(error.response.data.errors[0].errorMessage || 'Something went wrong');
        }
    };

    const formik = useFormik({
        initialValues: defaultState,
        validateOnChange: false,
        validationSchema: yup.object().shape({
            password: yup
                .string()
                .min(6, 'Password must be at least 6 characters')
                .max(16, 'Password must not be more than 16 characters')
                .required('password is required'),
            confirm_password: yup.string().when('password', {
                is: val => !!(val && val.length > 0),
                then: yup.string().oneOf([yup.ref('password')], 'Must match password'),
            }),
        }),
        onSubmit: resetPassword,
    });

    const { values, errors, handleChange } = formik;
    const onChange = (name, value) => handleChange({ target: { name, value } });
    const isAuthenticated = Boolean(resetToken);
    const fromWiflixHome =
        Boolean(search.fromUrl) && search.fromUrl.toString() === 'https://www.wi-flix.com';

    useEffect(() => {
        if (fromWiflixHome) {
            history.push('/forgot-password');
        }
    }, [fromWiflixHome, history]);

    if (!isAuthenticated) {
        if (fromWiflixHome) return null;

        return (
            <Layout>
                <div
                    style={{
                        fontSize: 18,
                        color: '#FFF',
                        padding: 20,
                        textAlign: 'center',
                        width: '100%',
                    }}
                >
                    Currently Unavailable
                </div>
            </Layout>
        );
    }

    return (
        <Layout>
            <section className={classes.login}>
                {isAuthenticated && (
                    <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
                        <div className={classes.controls}>
                            <Input
                                variant="outlined"
                                name="password"
                                value={values.password}
                                placeholder="New Password"
                                type="password"
                                autoComplete="new-password"
                                fullWidth
                                error={errors.password}
                                onChange={onChange}
                            />
                        </div>

                        <div className={classes.controls}>
                            <Input
                                variant="outlined"
                                name="confirm_password"
                                value={values.confirm_password}
                                placeholder="Repeat Password"
                                type="password"
                                autoComplete="off"
                                fullWidth
                                onChange={onChange}
                                error={errors.confirm_password}
                            />
                        </div>

                        {Boolean(submitMessage) && (
                            <FormHelperText
                                error
                                classes={{
                                    root: classes.errorText,
                                }}
                            >
                                {submitMessage}
                            </FormHelperText>
                        )}

                        <fieldset className={classes.formActions}>
                            <div className={classes.btnWrapper}>
                                <Button
                                    loading={formik.isSubmitting}
                                    disabled={formik.isSubmitting}
                                    classes={{
                                        root: classes.loginButton,
                                        disabled: classes.btnDisabled,
                                    }}
                                    fullWidth
                                    variant="contained"
                                    type="submit"
                                >
                                    Reset Password
                                </Button>
                            </div>
                        </fieldset>
                    </form>
                )}

                {!isAuthenticated && (
                    <FormHelperText
                        error
                        classes={{
                            root: classes.errorText,
                        }}
                    >
                        You have to be Authenticated to access this page
                    </FormHelperText>
                )}
            </section>
        </Layout>
    );
}

ResetPassword.getInitialProps = async () => {
    await Promise.resolve();
};

ResetPassword.getChunkName = () => {
    return 'ResetPassword';
};

const ResetPasswordRoute = withRoot(ResetPassword);

export { ResetPasswordRoute as ResetPassword };
