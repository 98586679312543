const asyncDispatch = store => next => action => {
    let syncActivityFinished = false;
    let actionQueue = [];

    function flushQueue() {
        actionQueue.forEach(a => store.dispatch(a));
        actionQueue = [];
    }

    const actionWithAsyncDispatch = {
        ...action,
        asyncDispatch: asyncAction => {
            actionQueue = actionQueue.concat([asyncAction]);

            if (syncActivityFinished) {
                flushQueue();
            }
        },
    };

    next(actionWithAsyncDispatch);
    syncActivityFinished = true;
    flushQueue();
};

export { asyncDispatch };
