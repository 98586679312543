/* eslint-disable react/prop-types */

import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { YesNoButtons } from '../YesNoButtons';

const useStyles = makeStyles(() => ({
    root: {
        boxShadow:
            '0 24px 38px 3px rgba(0,0,0,.14), 0 9px 46px 8px rgba(0,0,0,.12), 0 11px 15px -7px rgba(0,0,0,.2)',
        background: '#fff',
        borderRadius: 2,
        display: 'block',
        height: 'auto',
        maxHeight: '60vh',
        maxWidth: '60%',
        overflow: 'hidden',
        '& main$content': {
            fontSize: 13,
            fontWeight: 400,
            boxSizing: 'border-box',
            flexGrow: 1,
            color: 'rgba(0,0,0,.87)',
            overflow: 'auto',
            padding: '0 24px',
            width: '100%',
        },
        '& footer$content': {
            boxSizing: 'border-box',
            flexShrink: 0,
            padding: '0 8px 8px',
            width: '100%',
        },
    },
    focusTrap: {
        '&$content': {
            height: 'inherit',
            maxHeight: 'inherit',
            minHeight: 'inherit',
            width: '100%',
            display: 'block',
        },
    },
    content: {
        '& > $contentWrapper header': {
            MozBoxSizing: 'border-box',
            boxSizing: 'border-box',
            padding: '24px 24px 0',
            width: '100%',
            flexShrink: 0,
        },
    },
    contentWrapper: {
        height: 'inherit',
        maxHeight: 'inherit',
        minHeight: 'inherit',
    },
    wrapperContent: {
        display: 'flex',
        flexDirection: 'column',
        height: 'inherit',
        overflow: 'hidden',
        maxHeight: 'inherit',
        minHeight: 'inherit',
    },
    dialogHeader: {
        fontSize: 20,
        fontWeight: 500,
        marginBottom: 20,
    },
    dialogContent: {
        fontSize: '1.25em',
        fontWeight: 400,
        paddingTop: 8,
        paddingBottom: 8,
    },
    footer: {
        display: 'flex',
        flexShrink: 0,
        justifyContent: 'flex-end',
    },
}));

function Confirm(props) {
    const classes = useStyles();
    const {
        cancelLabel,
        acceptLabel,
        title,
        content,
        promise,
        closeModal,
        onAccept,
        hideCancel,
        onCancel,
        submitLoading,
        actionsClassName,
    } = props;

    const confirm = () => {
        if (typeof onAccept === 'function') {
            onAccept();
        }

        closeModal();
        promise.resolve();
    };

    const cancel = () => {
        if (typeof onCancel === 'function') {
            onCancel();
        }

        closeModal();
        promise.reject();
    };

    return (
        <div className={classes.root}>
            <div className={classnames(classes.focusTrap, classes.content)}>
                <div className={classes.contentWrapper} style={{ outline: 'none' }}>
                    <header className={classes.content}>
                        <div className={classes.dialogHeader}>{title}</div>
                    </header>
                    <main className={classes.content}>
                        <div
                            className={classes.dialogContent}
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{ __html: content }}
                        />
                    </main>
                    <footer className={classes.content}>
                        <div className={classes.footer}>
                            <YesNoButtons
                                cancel={cancel}
                                accept={confirm}
                                cancelLabel={cancelLabel}
                                acceptLabel={acceptLabel}
                                hideCancel={hideCancel}
                                submitLoading={submitLoading}
                                className={actionsClassName}
                            />
                        </div>
                    </footer>
                </div>
            </div>
        </div>
    );
}

Confirm.propTypes = {
    promise: PropTypes.shape({
        reject: PropTypes.func.isRequired,
        resolve: PropTypes.func.isRequired,
    }),
    closeModal: PropTypes.func,
    onAccept: PropTypes.func,
    onCancel: PropTypes.func,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    hideCancel: PropTypes.bool,
};

Confirm.defaultProps = {
    onAccept: () => {},
    onCancel: () => {},
    hideCancel: false,
    closeModal: () => {},
    promise: {
        reject: () => {},
        resolve: () => {},
    },
};

export { Confirm };
