import { colors } from '@mui/material';

const MuiLinearProgress = {
    styleOverrides: {
        root: {
            borderRadius: 3,
            overflow: 'hidden',
        },
        colorPrimary: {
            backgroundColor: colors.blueGrey[50],
        },
    },
};

export { MuiLinearProgress };
