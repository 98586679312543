import { LoadingButton as Button } from '@mui/lab';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { getAuth, RecaptchaVerifier } from 'firebase/auth';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { Link, useHistory } from 'react-router-dom';
import * as yup from 'yup';

import { PhoneNumberInput } from '../../components/PhoneNumberInput';
import { withRoot } from '../../components/Root';
import { firebaseApp } from '../../firebase-app';
import { useFragmentContext } from '../../hooks/useFragmentContext';
import { Checkout as Layout } from '../../layouts/Checkout';
import { apiAxiosInstance } from '../../utils/axiosInstance';

const useStyles = makeStyles(theme => ({
    '@global': {
        '.PhoneInputCountry': {
            marginRight: 8,
            verticalAlign: 'middle',
            marginTop: -8,
            fontSize: 18,
        },
    },
    login: {
        flex: 1,
        '& form': {
            flex: 1,
        },
    },
    title: {
        color: '#FFF',
        lineHeight: '1.625!important',
        fontSize: '1.8rem',
        marginBottom: 0,
        marginTop: 0,
        fontWeight: 600,
        paddingBottom: '2rem',
    },
    errorText: {
        fontSize: '1.45rem',
        fontWeight: 'normal',
        marginBottom: theme.spacing(),
    },
    controls: {
        marginBottom: theme.spacing(),
        '& input': {
            fontSize: 14,
        },
    },
    formActions: {
        marginTop: theme.spacing(3),
        background: 'none',
    },
    btnWrapper: {
        position: 'relative',
    },
    loginButton: {
        padding: '1.6rem',
        borderRadius: '.8rem',
        textTransform: 'uppercase',
        background: '#ff6c2f',
        fontSize: 16,
        color: '#FFF',
        fontWeight: 600,
        '&:hover': {
            background: '#ff6c2f',
        },
    },
    btnDisabled: {
        backgroundColor: '#cccccc!important',
        color: '#666666!important',
    },
    error: {
        fontSize: '1.45rem',
        textAlign: 'center',
    },
    loginMeta: {
        textAlign: 'center',
        marginTop: theme.spacing(3),
    },
    weightyLink: {
        paddingLeft: theme.spacing(0.5),
        color: '#ff6c2f',
        fontWeight: 600,
        '&:hover': {
            textDecoration: 'none',
            cursor: 'pointer',
        },
    },
    metaText: {
        color: '#868788',
        paddingLeft: theme.spacing(),
    },
    signInPlaceholder: {
        color: '#FFF',
        fontSize: 18,
        textAlign: 'center',
    },
}));

const defaultState = {
    phone_number: '',
};

const auth = getAuth(firebaseApp);

const ForgotPassword = () => {
    const classes = useStyles();
    const { countryCode } = useFragmentContext();
    const [preferredCountry, setPreferredCountryCode] = useState(countryCode || 'GH');
    const [submitMessage, setSubmitMessage] = useState(null);
    const [submitEnabled, setSubmitEnabled] = useState(true);
    const [tries, setTries] = useState(0);
    const history = useHistory();

    useEffect(() => {
        window.recaptchaVerifier = new RecaptchaVerifier(
            'submit',
            {
                size: 'invisible',
                // eslint-disable-next-line no-unused-vars
                callback(response) {},
                'expired-callback': function expiredCallback(err) {
                    // eslint-disable-next-line no-console
                    console.error(err);
                    setSubmitEnabled(false);
                },
            },
            auth,
        );
    }, []);

    const getToken = async (values, { setSubmitting }) => {
        const parsedPhoneNumber = parsePhoneNumber(values.phone_number, preferredCountry);
        const formattedPhoneNumber = parsedPhoneNumber.number;
        try {
            const { data } = await apiAxiosInstance.post('/auth/v1/password-reset-token', {
                phoneNumber: formattedPhoneNumber,
                notifyUser: false,
            });
            const {
                body: { resetToken },
            } = data;
            setSubmitting(false);
            history.push(`/reset-password/?token=${resetToken}`);
        } catch (error) {
            setSubmitting(false);
            setTries(tries + 1);
            setSubmitMessage(error.response.data.errors[0].errorMessage || 'Something went wrong');
        }
    };

    const formik = useFormik({
        initialValues: defaultState,
        validateOnChange: false,
        validationSchema: yup.object().shape({
            phone_number: yup
                .string()
                .required('Invalid phone number')
                .test(
                    'isValidPhoneNumber',
                    'Phone number must be a valid international phone number',
                    value => {
                        if (!value) return true;

                        return isValidPhoneNumber(value, preferredCountry);
                    },
                ),
        }),
        onSubmit: getToken,
    });

    const { values, errors, handleChange } = formik;
    const onChange = (name, value) => {
        handleChange({ target: { name, value } });
    };

    return (
        <Layout>
            <section className={classes.login}>
                <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
                    <Typography className={classes.title} variant="h3">
                        Enter your phone number and we&apos;ll send you a otp to reset your
                        password.
                    </Typography>
                    <div style={{ marginBottom: 16 }} className={classes.controls}>
                        <PhoneNumberInput
                            name="phone_number"
                            error={errors.phone_number}
                            value={values.phone_number}
                            placeholder="Enter your phone number"
                            onChange={onChange}
                            countryCode={preferredCountry || countryCode}
                            setCountryCode={setPreferredCountryCode}
                        />
                    </div>

                    {Boolean(submitMessage) && (
                        <FormHelperText
                            error
                            classes={{
                                root: classes.errorText,
                            }}
                        >
                            {submitMessage}
                        </FormHelperText>
                    )}

                    <fieldset className={classes.formActions}>
                        <div className={classes.btnWrapper}>
                            <Button
                                id="submit"
                                loading={formik.isSubmitting}
                                disabled={formik.isSubmitting || !submitEnabled}
                                classes={{
                                    root: classes.loginButton,
                                    disabled: classes.btnDisabled,
                                }}
                                fullWidth
                                variant="contained"
                                type="submit"
                            >
                                <span>{tries > 1 ? 'Resend' : 'Proceed'}</span>
                            </Button>
                        </div>
                    </fieldset>

                    <fieldset className={classes.loginMeta}>
                        <Typography className={classes.metaText} variant="subtitle2">
                            <Link
                                className={classes.weightyLink}
                                style={{
                                    marginTop: 7,
                                }}
                                to="/login"
                            >
                                Back to Sign in
                            </Link>
                        </Typography>
                    </fieldset>
                </form>
            </section>
        </Layout>
    );
};

ForgotPassword.getInitialProps = async () => {
    await Promise.resolve();
};

ForgotPassword.getChunkName = () => {
    return 'ForgotPassword';
};

const ForgotPasswordRoute = withRoot(ForgotPassword);

export { ForgotPasswordRoute as ForgotPassword };
