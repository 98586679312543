import moment from 'moment';

export const prettyDateShort = date => {
    return moment(date).calendar(null, {
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        nextWeek: 'MMM D',
        lastDay: '[Yesterday]',
        lastWeek: 'dddd, MMM D',
        sameElse: 'MMMM D, YYYY',
    });
};
