import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import AppStore from '../../images/app_store.png';
import PlayStore from '../../images/google_play.png';

const useStyles = makeStyles(theme => ({
    downloadButtons: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        padding: [theme.spacing(2), 0],
        '& img': {
            maxWidth: '100%',
            border: 0,
            margin: '0 auto',
            display: 'block',
            marginTop: theme.spacing(1),
        },
    },
    apps: {
        color: '#FFF',
        fontWeight: 500,
        textAlign: 'center',
        fontSize: '1.6rem!important',
        marginTop: theme.spacing(2),
    },
}));

function AppsBanner() {
    const classes = useStyles();

    return (
        <div>
            <Typography className={classes.apps} variant="h3">
                Download our apps
            </Typography>
            <div className={classes.downloadButtons}>
                <Link
                    href="https://play.google.com/store/apps/details?id=com.smartboxtv.nunchee.android.wiflixchannel"
                    target="_blank"
                >
                    <img width="200" src={PlayStore} alt="PlayStore" />
                </Link>

                <Link href="https://apps.apple.com/us/app/wi-flix/id1528421824" target="_blank">
                    <img width="200" src={AppStore} alt="AppStore" />
                </Link>
            </div>
        </div>
    );
}

export { AppsBanner };
