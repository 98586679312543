import { makeStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles(() => ({
    spinner: {
        position: 'absolute',
        top: '30%',
        left: '50%',
        transform: 'translate(-30%,-50%)',
    },
}));

function Spinner() {
    const classes = useStyles();

    return (
        <div className={classes.spinner}>
            <CircularProgress color="secondary" size={48} />
        </div>
    );
}

export { Spinner };
