/* eslint-disable react/prop-types, camelcase */

import { LoadingButton as Button } from '@mui/lab';
import FormHelperText from '@mui/material/FormHelperText';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import { useFormik } from 'formik';
import queryString from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';

import { Country } from '../../../shared/enums/country';
import { Network } from '../../../shared/enums/network';
import { plansMap } from '../../../shared/plans-map';
import { withRoot } from '../../components/Root';
import { Spinner } from '../../components/Spinner';
import { usePackages } from '../../hooks/packages';
import { usePrevious } from '../../hooks/previous';
import { useFragmentContext } from '../../hooks/useFragmentContext';
import { updateEncryptedMsisdn } from '../../utils/updateEncryptedMsisdn';
import { createCheckout } from './async';
import { CheckoutModal } from './CheckoutModal';
import { CheckoutTab } from './CheckoutTab';
import { Promotionals, usePromotionalBgImageStyles } from './promotionals';

const PaymentMethodType = {
    MobileMoney: 'mobile-money',
};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        [theme.breakpoints.up('sm')]: {
            padding: '4rem!important',
        },
    },
    content: {
        // alignItems: "center",
        display: 'flex',
        flexDirection: 'column',
        padding: '2rem!important',
        fontSize: '1.4rem!important',
        gap: '2rem!important',
        color: '#FFF',
        [theme.breakpoints.up('lg')]: {
            fontSize: '2.4rem!important',
            padding: '6.4rem!important',
            gap: '6.4rem!important',
            lineHeight: '3.2rem!important',
        },
    },
    title: {
        fontWeight: 900,
    },
    packages: {
        display: 'flex',
        flexDirection: 'column',
        gap: '2rem!important',
        // alignItems: "center",
        justifyContent: 'center',
        [theme.breakpoints.up('lg')]: {
            gap: '4rem!important',
        },
    },
    activeTab: {
        color: '#ff6c2f!important',
    },
    indicator: {
        backgroundColor: '#ff6c2f!important',
    },
    tab: {
        fontSize: '1.6rem',
        padding: [theme.spacing(), theme.spacing(3)],
        [theme.breakpoints.down('md')]: {
            maxWidth: 180,
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: 312,
        },
    },
    tabFlexContainer: {
        borderBottom: '1px solid #a3a9b2',
    },
    packageList: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '1.6rem!important',
        flexWrap: 'wrap',
    },
    actions: {
        display: 'flex',
        gap: '1rem!important',
        alignItems: 'center',
        flexDirection: 'column',
    },
    btn: {
        paddingLeft: '2.4rem!important',
        paddingRight: '2.4rem!important',
        paddingTop: '1.2rem!important',
        paddingBottom: '1.2rem!important',
        fontSize: '1.6rem!important',
        fontWeight: 600,
        '&:hover': {
            background: theme.palette.secondary.main,
        },
    },
    disabledBtn: {
        backgroundColor: `${theme.palette.secondary.main}!important`,
        color: '#FFF!important',
    },
    error: {
        fontSize: '1.6rem!important',
    },
    background: {
        backgroundColor: '#DEDEDE',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: -1,
    },
}));

const defaultState = {
    plan: '',
};

const CHECKOUT_FAILURE = 'checkout failed';
const initiateCheckout = async request => {
    try {
        const response = await createCheckout(request);
        return { checkoutURL: response.checkoutURL };
    } catch (err) {
        let errorMessage = err.message || CHECKOUT_FAILURE;
        if (errorMessage.indexOf('User need to update profile to subscribe plan ') > -1) {
            try {
                await updateEncryptedMsisdn();
                const response = await createCheckout(request);
                return { checkoutURL: response.checkoutURL };
            } catch (e) {
                errorMessage = e.message || 'Something went wrong';
            }
        }

        throw new Error(errorMessage);
    }
};

const integratedCountries = ['ke', 'ng', 'gh', 'zm'];

const CheckoutForm = ({ history, location }) => {
    const classes = useStyles();
    const promotionalBgImageClasses = usePromotionalBgImageStyles();
    const { user } = useFragmentContext();
    const search = queryString.parse(location.search);

    const flowPlan = search.plan;
    const [flowExecuted, setFlowExecuted] = useState(false);
    const { promotional } = search;
    const [submitErrorMessage, setSubmitErrorMessage] = useState(null);
    const network = user.network || search.nw;
    const { loading, loaded, packages } = usePackages(promotional);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [checkoutURL, setCheckoutURL] = useState('');

    const packagePrefix = packageObj => {
        if (packageObj.name.endsWith('Plus')) return 'Plus';
        if (packageObj.name.startsWith('TVOD')) return 'TVOD';
        return '';
    };

    const getInitialActiveTab = () => {
        if (user.network && user.network !== Network.OTHER && user.country_code !== Country.GHANA) {
            return `access~${user.country_code}`;
        }

        return '';
    };

    const grouped = (packages || []).reduce((acc, curr) => {
        const key = [packagePrefix(curr), curr.type, curr.user_group].filter(Boolean).join('~');
        if (acc[key]) {
            acc[key] = acc[key].concat([curr]);
            return acc;
        }

        acc[key] = [curr];
        return acc;
    }, {});

    const [activeTab, setActiveTab] = useState(getInitialActiveTab());

    // Make group with rest_of_world the last item in the grouped
    const restOfWorldGroup = grouped['access~rest_of_world'];
    if (restOfWorldGroup) {
        delete grouped['access~rest_of_world'];
        grouped['access~rest_of_world'] = restOfWorldGroup;
    }

    const prevGrouped = usePrevious(grouped);
    const prevActiveTab = usePrevious(activeTab);
    const promotionalMetadata = Promotionals[promotional];

    const getCheckoutRequest = useCallback(
        planId => {
            const clickId = search.clickId || search.click_id;
            const checkoutRequest = {
                planId,
                ...(clickId && { externalServiceId: decodeURIComponent(clickId) }),
            };

            return checkoutRequest;
        },
        [search],
    );

    const formik = useFormik({
        initialValues: defaultState,
        validationSchema: yup.object().shape({
            plan: yup.string().required('No plan selected'),
        }),
        onSubmit: async (values, { setSubmitting }) => {
            const activePlan = packages.find(plan => plan.id === values.plan);

            if (activePlan.paymentMethodType === PaymentMethodType.MobileMoney) {
                history.push(`/mobile-money-checkout?plan=${values.plan}`, {
                    phoneNumber: user.phone_number,
                    network,
                });
                return;
            }

            try {
                const { checkoutURL: responseURL } = await initiateCheckout(
                    getCheckoutRequest(values.plan),
                );
                setSubmitting(false);
                // Check if checkoutURL contains the specified substring
                if (responseURL.includes('/subscription/create')) {
                    setCheckoutURL(responseURL); // Set the checkoutURL state
                    setIsModalOpen(true); // Open the modal
                } else {
                    window.location.href = responseURL;
                }
            } catch (err) {
                setSubmitting(false);
                setSubmitErrorMessage(err.message);
            }
        },
    });

    const { values, errors, handleChange, isSubmitting } = formik;
    const onChange = useCallback(
        (name, value) => handleChange({ target: { name, value } }),
        [handleChange],
    );

    useEffect(() => {
        if (
            Object.keys(prevGrouped || {}).length === 0 &&
            Object.keys(grouped || {}).length > 0 &&
            !activeTab
        ) {
            const group = Object.keys(grouped)[0];
            setActiveTab(group);
            onChange('plan', grouped[group][0].id);
        }
    }, [grouped, prevGrouped, activeTab, onChange]);

    useEffect(() => {
        if (prevActiveTab && prevActiveTab !== activeTab) {
            const group = grouped[activeTab];
            onChange('plan', group[0].id);
        }
    }, [activeTab, grouped, prevActiveTab, onChange]);

    useEffect(() => {
        const isIntegratedCountry =
            Boolean(user) && integratedCountries.indexOf(user.countryCode?.toLowerCase()) > -1;
        if (isIntegratedCountry && !network) {
            history.push({
                pathname: '/select-network',
            });
        }
    }, [history, network, user]);

    // Side effect to handle instance checkout
    useEffect(() => {
        const instantCheckout = async () => {
            if (!flowExecuted && Boolean(flowPlan)) {
                const mappedPlan = plansMap.get(flowPlan) || flowPlan;
                if (packages?.length > 0) {
                    if (!packages.some(p => p.id === mappedPlan)) {
                        setFlowExecuted(true);
                        setSubmitErrorMessage('Please select a plan');
                        return;
                    }

                    try {
                        const { checkoutURL: responseURL } = await initiateCheckout(
                            getCheckoutRequest(mappedPlan),
                        );
                        window.location.href = responseURL;
                    } catch (err) {
                        setFlowExecuted(true);
                        setSubmitErrorMessage(err.message);
                    }
                }
            }
        };

        instantCheckout();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flowExecuted, flowPlan, packages]);

    if (loading || !loaded || (!!flowPlan && !flowExecuted)) {
        return <Spinner />;
    }

    const onTabChange = (evt, desiredTab) => {
        setActiveTab(desiredTab);
    };

    const getSuffix = item => {
        if (item.network) {
            return ` (${item.network})`;
        }

        return '';
    };

    const hasPromotionalBackground = Boolean(promotionalMetadata?.backgroundImageClass);

    return (
        <div className={classes.root}>
            {hasPromotionalBackground && (
                <div
                    className={classnames(
                        classes.background,
                        promotionalBgImageClasses[promotionalMetadata?.backgroundImageClass],
                    )}
                />
            )}
            <form onSubmit={formik.handleSubmit} className={classes.content}>
                <div className={classes.title}>You do not have an active subscription</div>
                <div className={classes.packages}>
                    {Boolean(activeTab) && (
                        <Tabs
                            className={classes.tabs}
                            classes={{
                                indicator: classes.indicator,
                                flexContainer: classes.tabFlexContainer,
                            }}
                            value={activeTab}
                            onChange={onTabChange}
                        >
                            {Object.keys(grouped).map(tab => {
                                let label = `Unlimited Access + Bundle${getSuffix(
                                    grouped[tab][0],
                                )}`;
                                if (tab.startsWith('TVOD')) {
                                    label = `TVOD (Chioma) Access`;
                                } else if (tab.startsWith('access')) {
                                    label = `Unlimited Access${getSuffix(grouped[tab][0])}`;
                                } else if (tab.startsWith('Plus')) {
                                    label = `Unlimited Access Plus ${getSuffix(grouped[tab][0])}`;
                                }

                                return (
                                    <Tab
                                        key={tab}
                                        value={tab}
                                        label={label}
                                        classes={{
                                            selected: classes.activeTab,
                                            root: classes.tab,
                                        }}
                                    />
                                );
                            })}
                        </Tabs>
                    )}

                    {activeTab && (
                        <CheckoutTab
                            tab={activeTab}
                            plans={grouped[activeTab]}
                            selectedPlan={values.plan}
                            onSelectPlan={value => onChange('plan', value)}
                        />
                    )}
                </div>

                {Boolean(errors.plan) && (
                    <FormHelperText error classes={{ error: classes.error }}>
                        {errors.plan}
                    </FormHelperText>
                )}

                {Boolean(submitErrorMessage) && (
                    <FormHelperText error classes={{ error: classes.error }}>
                        {submitErrorMessage}
                    </FormHelperText>
                )}

                <div className={classes.actions}>
                    <Button
                        loading={isSubmitting}
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                        className={classes.btn}
                        classes={{ disabled: classes.disabledBtn }}
                        color="secondary"
                    >
                        Continue
                    </Button>
                </div>
            </form>
            <CheckoutModal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                location={location}
                history={history}
                checkoutURL={checkoutURL}
            />
        </div>
    );
};

CheckoutForm.getInitialProps = async () => {
    await Promise.resolve();
};

CheckoutForm.getChunkName = () => {
    return 'SelectNetwork';
};

const CheckoutRoute = withRoot(CheckoutForm);

export { CheckoutRoute as Checkout };
