/* eslint-disable react/prop-types */
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from '@mui/material/Button';

const styles = () => ({
    yesNoButtons: {
        marginTop: 16,
        display: 'flex',
        alignContent: 'space-between',
    },
    btnNo: {
        marginLeft: '20px!important',
        marginRight: '20px!important',
    },
    btnAccept: {},
});

const YesNoButtons = withStyles(styles)(
    ({ classes, accept, cancel, className, submitButtonType, ...props }) => {
        return (
            <div className={classnames(className, classes.yesNoButtons)}>
                <Button
                    classes={{ root: classes.btnAccept }}
                    disabled={props.disabled}
                    type={submitButtonType}
                    variant="contained"
                    color="primary"
                    onClick={accept}
                >
                    {props.acceptLabel}
                </Button>
                {!props.hideCancel && (
                    <Button variant="outlined" className={classes.btnNo} onClick={cancel}>
                        {props.cancelLabel}
                    </Button>
                )}
            </div>
        );
    },
);

YesNoButtons.defaultProps = {
    acceptLabel: 'Ok',
    cancelLabel: 'Cancel',
    submitButtonType: 'submit',
    cancel: () => {},
    accept: () => {},
    disabled: false,
    hideCancel: false,
    className: undefined,
    submitLoading: false,
};

YesNoButtons.propTypes = {
    cancelLabel: PropTypes.string,
    acceptLabel: PropTypes.string,
    cancel: PropTypes.func,
    accept: PropTypes.func,
    disabled: PropTypes.bool,
    hideCancel: PropTypes.bool,
    className: PropTypes.string,
    submitLoading: PropTypes.bool,
    submitButtonType: PropTypes.string,
};

export { YesNoButtons };
