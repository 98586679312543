import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import yn from 'yn';

import { AppsBanner } from '../../components/AppsBanner';
import { withRoot } from '../../components/Root';
import { Spinner } from '../../components/Spinner';
import { useOrder } from '../../hooks/useOrder';
import { Checkout } from '../../layouts/Checkout';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        gap: '1.6rem!important',
        alignItems: 'center',
    },
    watch: {
        cursor: 'pointer',
        textAlign: 'center',
        '& a': {
            color: '#FFF',
        },
    },
    sub: {
        color: '#FFF',
        textAlign: 'center',
        marginBottom: theme.spacing(3),
        margin: '0 auto',
        lineHeight: 1.3,
        [theme.breakpoints.up('lg')]: {
            fontSize: '2rem!important',
        },
    },

    contentWrap: {
        paddingBottom: '0px!important',
    },
    invalid: {
        color: '#FFF',
        textAlign: 'center',
        width: '100%',
        fontSize: '1.65rem',
        paddingBottom: 24,
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column',
    },
    btn: {
        paddingLeft: '2.4rem!important',
        paddingRight: '2.4rem!important',
        paddingTop: '1.2rem!important',
        paddingBottom: '1.2rem!important',
        fontSize: '1.6rem!important',
        fontWeight: 600,
        '&:hover': {
            background: theme.palette.secondary.main,
        },
    },
    submitButton: {
        textTransform: 'none',
        fontSize: 16,
        fontWeight: 600,
        textAlign: 'center',
        margin: '0 auto',
        display: 'inline-block',
        minWidth: 128,
    },
    disabledBtn: {
        backgroundColor: `${theme.palette.secondary.main}!important`,
        color: '#FFF!important',
        opacity: '0.8!important',
    },
    error: {
        fontSize: '1.6rem!important',
    },
}));

const OneMinute = 1 * 60;

const CheckoutSuccess = ({ location }) => {
    const classes = useStyles();
    const [elapsed, setElapsed] = useState('02:00');
    const [delayed, setDelayed] = useState(false);
    const interval = React.useRef();
    const urlSearchParams = new URLSearchParams(location.search);
    const orderId = urlSearchParams.get('order_id') || urlSearchParams.get('transID');
    const { loaded, order } = useOrder(orderId);

    useEffect(() => {
        const startTimer = duration => {
            let timer = duration;
            let minutes;
            let seconds;

            interval.current = setInterval(() => {
                minutes = parseInt(timer / 60, 10);
                seconds = parseInt(timer % 60, 10);

                minutes = minutes < 10 ? `0${minutes}` : minutes;
                seconds = seconds < 10 ? `0${seconds}` : seconds;

                setElapsed(`${minutes}:${seconds}`);

                // eslint-disable-next-line no-plusplus
                if (--timer < 0) {
                    clearInterval(interval.current);
                    setDelayed(true);
                }
            }, 1000);
        };

        if (order && order.processed && interval.current) {
            clearInterval(interval.current);
            return;
        }

        if (order && !order.processed && !interval.current) {
            startTimer(2 * OneMinute);
        }
    }, [interval, order]);

    const retry = () => {
        let target = '/';
        urlSearchParams.delete('order_id');

        if (Array.from(urlSearchParams.entries()).length) {
            target = `${target}?${urlSearchParams.toString()}`;
        }

        window.location.href = target;
    };

    if (!loaded) {
        return <Spinner />;
    }

    if (!order) {
        return (
            <Checkout classes={{ contentWrap: classes.contentWrap }}>
                <div className={classes.root}>
                    <div className={classes.flexColumn}>
                        <Typography gutterBottom className={classes.sub} variant="h5">
                            Invalid Order
                        </Typography>

                        <Typography className={classes.watch} variant="h3">
                            <Link href="https://checkout.wi-flix.com" target="_self">
                                Go home
                            </Link>
                        </Typography>
                    </div>
                </div>
            </Checkout>
        );
    }

    const isSuccess = order.status && order.status.toLowerCase() === 'success';
    const requiresConfirm = urlSearchParams.get('requires_confirm');

    const pendingMessage =
        order.variant === 'checkout' && !yn(requiresConfirm)
            ? "Payment processing. We'll update you when payment is received."
            : 'Almost there! Please quickly confirm subscription via USSD prompt. And start streaming after you receive SMS confirmation of your subscription.';

    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Checkout classes={{ contentWrap: classes.contentWrap }}>
            <div className={classes.root}>
                {
                    // TODO:Fix:  possible error here. processed does not mean subscription went through
                    order.processed && (
                        <div>
                            {isSuccess && (
                                <div className={classes.flexColumn}>
                                    <Typography gutterBottom className={classes.sub} variant="h5">
                                        You have successfully been subscribed to Wi-flix.
                                    </Typography>
                                    <Typography className={classes.watch} variant="h3">
                                        <Link
                                            href={order.callback_url || 'https://play.wi-flix.com'}
                                            target="_self"
                                        >
                                            <Button
                                                variant="contained"
                                                className={classes.submitButton}
                                            >
                                                Stream Now
                                            </Button>
                                        </Link>
                                    </Typography>
                                </div>
                            )}

                            {!isSuccess && (
                                <div className={classes.flexColumn}>
                                    <Typography gutterBottom className={classes.sub} variant="h5">
                                        Subscription failed!
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        onClick={retry}
                                        className={classes.btn}
                                        color="secondary"
                                    >
                                        Retry
                                    </Button>
                                </div>
                            )}
                        </div>
                    )
                }

                {!order.processed && (
                    <Typography gutterBottom className={classes.sub} variant="h5">
                        {pendingMessage}
                    </Typography>
                )}

                {!order.processed && (
                    <Button
                        variant="contained"
                        onClick={retry}
                        className={classes.btn}
                        disabled={!delayed}
                        classes={{ disabled: classes.disabledBtn }}
                        color="secondary"
                    >
                        {delayed ? 'Retry' : `Retry in ${elapsed}...`}
                    </Button>
                )}

                <AppsBanner />
            </div>
        </Checkout>
    );
};

CheckoutSuccess.getInitialProps = async () => {
    await Promise.resolve();
};

CheckoutSuccess.getChunkName = () => {
    return 'CheckoutSuccess';
};

const CheckoutSuccessRoute = withRoot(CheckoutSuccess);

export { CheckoutSuccessRoute as CheckoutSuccess };
