import PropTypes from 'prop-types';
import 'react-phone-number-input/style.css';
import { makeStyles } from '@mui/styles';

import { CountrySelect } from '../../pages/Login/CountrySelect';
import { Input as TextInput } from '../Input';

const useStyles = makeStyles(() => ({
    '@global': {
        '.PhoneInputCountry': {
            marginRight: 8,
            verticalAlign: 'middle',
            fontSize: 18,
        },
    },
    root: {
        display: 'flex',
        flexDirection: 'row',
    },
    phoneInput: {
        '& input': {
            borderRadius: '.8rem',
        },
    },
}));

function Input({
    error,
    value,
    name,
    placeholder,
    onChange,
    disabled,
    countryCode,
    setCountryCode,
}) {
    const classes = useStyles();

    const isEmpty = !value;
    const isPhone = /^\d+$/i.test(value);
    const hasCountrySelect = !isEmpty && isPhone;

    return (
        <div className={classes.root}>
            <TextInput
                variant="outlined"
                name={name}
                value={value}
                placeholder={placeholder}
                type="text"
                fullWidth
                error={error}
                onChange={onChange}
                disabled={disabled}
                startAdornment={
                    hasCountrySelect ? (
                        <CountrySelect
                            value={countryCode}
                            onChange={country => setCountryCode(country)}
                        />
                    ) : null
                }
            />
        </div>
    );
}

Input.propTypes = {
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.string,
    value: PropTypes.string,
    name: PropTypes.string,
    disabled: PropTypes.bool,
    countryCode: PropTypes.string,
    setCountryCode: PropTypes.func,
};

Input.defaultProps = {
    placeholder: 'Enter your phone number',
    error: undefined,
    value: undefined,
    name: undefined,
    disabled: false,
    countryCode: undefined,
    setCountryCode: () => {},
};

export { Input as PhoneNumberInput };
