import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { Plan } from './Plan';

const useStyles = makeStyles(() => ({
    root: {},
}));

function CheckoutTab({ plans, selectedPlan, onSelectPlan }) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {plans
                .filter(plan => !plan.disabled)
                .map(plan => {
                    return (
                        <Plan
                            plan={plan}
                            key={plan.id}
                            selected={selectedPlan === plan.id}
                            onChange={onSelectPlan}
                        />
                    );
                })}
        </div>
    );
}

CheckoutTab.propTypes = {
    selectedPlan: PropTypes.string.isRequired,
    onSelectPlan: PropTypes.func.isRequired,
    plans: PropTypes.arrayOf(
        PropTypes.shape({
            disabled: PropTypes.bool,
        }),
    ).isRequired,
};

export { CheckoutTab };
