import queryString from 'query-string';

import { sessLogin } from './async';

export const sessionLogin = response =>
    new Promise((resolve, reject) => {
        // eslint-disable-next-line promise/catch-or-return,promise/always-return
        response.user.getIdToken().then(idToken => {
            sessLogin({ idToken }, err => {
                if (err) {
                    reject(err);
                    return;
                }

                resolve();
            });
        });
    });

export const createRedirectFunc =
    (location, returnTo = '/', skipReferrer) =>
    () => {
        const search = queryString.parse(location.search);
        if (Object.hasOwnProperty.call(search, 'referrer') && !skipReferrer) {
            // eslint-disable-next-line no-param-reassign
            returnTo = decodeURIComponent(search.referrer);
        }

        window.location.href = returnTo;
    };
