import { palette } from '../palette';

const MuiListItemIcon = {
    styleOverrides: {
        root: {
            color: palette.icon,
            minWidth: 32,
        },
    },
};

export { MuiListItemIcon };
