import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import ButtonBase from '@mui/material/ButtonBase';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        minWidth: 300,
        width: '100%',
    },
    image: {
        position: 'relative',
        height: 128,
        margin: theme.spacing(),
        background: '#808080',
        [theme.breakpoints.down('md')]: {
            width: '43% !important',
            height: 100,
        },
        '&:hover, &$focusVisible': {
            zIndex: 1,
            '& $imageBackdrop': {
                opacity: 0.15,
            },
            '& $imageMarked': {
                opacity: 0,
            },
            '& $imageTitle': {
                border: '4px solid currentColor',
            },
        },
    },
    focusVisible: {},
    imageButton: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.common.white,
    },
    imageSrc: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundSize: 'cover',
        backgroundPosition: 'center 40%',
    },
    imageBackdrop: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: theme.palette.common.black,
        opacity: 0,
        transition: theme.transitions.create('opacity'),
    },
    imageTitle: {
        position: 'relative',
        padding: `${theme.spacing(2)} ${theme.spacing(4)} calc(${theme.spacing(1)} + 6px)`,
    },
    imageMarked: {
        height: 3,
        width: 18,
        backgroundColor: theme.palette.common.white,
        position: 'absolute',
        bottom: -2,
        left: 'calc(50% - 9px)',
        transition: theme.transitions.create('opacity'),
    },
}));

const Network = ({ imageSrc, onClick }) => {
    const classes = useStyles();

    return (
        <ButtonBase
            onClick={onClick}
            focusRipple
            className={classes.image}
            focusVisibleClassName={classes.focusVisible}
            style={{
                width: '45%',
            }}
        >
            <span
                className={classes.imageSrc}
                style={{
                    background: `url(${imageSrc})`,
                    backgroundPosition: '50%',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    display: 'block',
                    textIndent: '-9999px',
                }}
            />
            <span className={classes.imageBackdrop} />
        </ButtonBase>
    );
};

Network.propTypes = {
    imageSrc: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

export { Network };
