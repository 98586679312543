import 'isomorphic-fetch';
import { formatUrl } from './ApiClient';
import { parseResponse } from './common';

const getBody = body => {
    if (body instanceof FormData) {
        return body;
    }

    if (typeof body !== 'string') {
        return JSON.stringify(body);
    }

    return body;
};

const createAsyncClient =
    () =>
    (method = 'POST', options = { headers: {} }, path, body, callback) => {
        const API_PREFIX = '/api';

        fetch(formatUrl(path, { apiPath: API_PREFIX }), {
            method,
            credentials: 'include',
            body: getBody(body),
            ...options,
        })
            // eslint-disable-next-line promise/always-return
            .then(response => {
                parseResponse(response, callback);
            })
            // eslint-disable-next-line promise/no-callback-in-promise
            .catch(err => callback(err));
    };

const async = createAsyncClient();

export { async, createAsyncClient };
