import { GlobalStyles, Theme } from '@mui/material';
import { Interpolation } from '@emotion/styled';

const styles = {
    html: {
        WebkitTextSizeAdjust: '100%',
    },
    'html, body': {
        fontSize: 10,
        height: '100%',
        fontFamily:
            '"Poppins",sans-serif,Roboto,-apple-system,BlinkMacSystemFont,Segoe UI,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans',
    },
    body: {
        maxWidth: '100%',
        margin: 0,
        lineHeight: 1.5,
        padding: 0,
        overflow: 'auto',
        overflowX: 'hidden!important',
        background: '#000000',
    },
    'body, button': {
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
    },
    '*': {
        boxSizing: 'border-box',
    },
    '#root, #app': {
        display: 'table',
        tableLayout: 'fixed',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
    },
    a: {
        color: 'hsl(202,40%,55%)',
        background: 'transparent',
        textDecoration: 'none',
    },
    button: {
        MozAppearance: 'none',
        WebkitAppearance: 'none',
        appearance: 'none',
        background: 'none',
    },
    fieldset: {
        minWidth: 0,
        border: 0,
    },
    'fieldset, legend': {
        margin: 0,
        padding: 0,
    },
    ':focus': {
        outline: 0,
    },
    'h1, h2, h3, h4, h5, h6': {
        border: 0,
        fontFamily: 'inherit',
        fontStyle: 'inherit',
        outline: 0,
        padding: 0,
        verticalAlign: 'baseline',
    },
    'ul, ol, p, h1, h2, h3, h4, h5, h6': {
        textRendering: 'auto',
    },
    'ul, ol': {
        padding: 0,
    },
    'p, h2, h3, h4, h5, h6': {
        wordWrap: 'break-word',
    },
    input: {
        lineHeight: 'normal',
    },
    'h1, h2, h3': {
        marginTop: 20,
        marginBottom: 10,
    },
    h3: {
        margin: '-7px 0 16px',
        bottom: -4,
        fontSize: 14,
        lineHeight: '16px',
    },
};

const CssReset = (): JSX.Element => {
    return <GlobalStyles styles={styles as Interpolation<Theme>} />;
};

export { CssReset };
