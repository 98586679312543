const SAFARICOM_ACCESS_TOKEN_URL = 'https://api.wi-flix.com/auth/v1/safaricom-he-access-token';
const MASKED_MSISDN_URL = 'https://identity.safaricom.com/partner/api/v2/fetchMaskedMsisdn';

interface EncryptedMsisdnResponse {
    ServiceResponse: {
        ResponseHeader: {
            ResponseCode: string;
            ResponseMsg: string;
        };
        ResponseBody: {
            Response: {
                Msisdn: string;
            };
        };
    };
}

export const updateEncryptedMsisdn = async (): Promise<void> => {
    const tokenResponse = await fetch(SAFARICOM_ACCESS_TOKEN_URL).then(res => {
        if (!res.ok) {
            throw new Error('Failed to fetch Safaricom access token');
        }

        return res.json();
    });
    const token = tokenResponse?.body?.accessToken;
    if (!token) {
        throw new Error('Failed to fetch Safaricom access token');
    }

    const encryptedMSISDNResponse: EncryptedMsisdnResponse = await fetch(MASKED_MSISDN_URL, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-type': 'application/json',
            'Accept-Encoding': 'application/json',
            'X-App': 'he-partner',
            'x-messageid': '1234',
            'X-Source-System': 'he-partner',
        },
    }).then(res => {
        if (!res.ok) {
            throw new Error('Failed to fetch masked MSISDN');
        }

        return res.json();
    });

    const maskedMsisdn =
        encryptedMSISDNResponse?.ServiceResponse?.ResponseBody?.Response?.Msisdn || '157795052283';
    if (!maskedMsisdn) {
        throw new Error(encryptedMSISDNResponse?.ServiceResponse?.ResponseHeader?.ResponseMsg);
    }

    const { success } = await fetch('/user/v1/update', {
        method: 'PATCH',
        headers: {
            'Content-type': 'application/json',
            'Accept-Encoding': 'application/json',
        },
        body: JSON.stringify({ encryptedMsisdn: maskedMsisdn }),
    }).then(res => {
        if (!res.ok) {
            throw new Error('Failed to update user data');
        }

        return res.json();
    });
    if (!success) {
        throw new Error('Failed to update user data');
    }
};
