import Modal from '@mui/material/Modal';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { cloneElement, useState } from 'react';

const useStyles = makeStyles(() => ({
    root: {
        position: 'absolute',
        pointerEvents: 'none',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 10,
        '& > $pane$modal': {
            justifyContent: 'center',
            alignItems: 'center',
            background: 'rgba(33,33,33,.4)',
            pointerEvents: 'auto',
            position: 'fixed',
            WebkitBackfaceVisibility: 'hidden',
            backfaceVisibility: 'hidden',
        },
        '& > $pane': {
            display: 'flex',
            position: 'absolute',
            zIndex: 11,
            pointerEvents: 'none',
        },
        '& > $pane > *': {
            display: 'flex',
            pointerEvents: 'auto',
        },
    },
    pane: {},
    modal: {},
}));

function Prompt(props) {
    const classes = useStyles();
    const { children, promise, contentProps, title } = props;
    const [isModalOpen, setIsModalOpen] = useState(true);

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <Modal
            aria-labelledby={title}
            aria-describedby="modal"
            open={isModalOpen}
            style={{ overflow: 'auto', zIndex: 120 }}
            className={classes.root}
        >
            <div
                style={{ left: 0, top: 0, bottom: 0, right: 0 }}
                className={classnames(classes.pane, classes.modal)}
            >
                {cloneElement(children, {
                    promise,
                    closeModal,
                    ...contentProps,
                })}
            </div>
        </Modal>
    );
}

Prompt.propTypes = {
    promise: PropTypes.shape({
        resolve: PropTypes.func.isRequired,
        reject: PropTypes.func.isRequired,
    }).isRequired,
    contentProps: PropTypes.shape({}),
    children: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
};

Prompt.defaultProps = {
    contentProps: {},
};

export { Prompt };
