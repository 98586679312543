import { Button, Theme } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import RouterLink from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import { get, toUpper } from 'lodash';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { Link, RouteComponentProps, useHistory, useLocation } from 'react-router-dom';

import { SubscriptionState } from '../../../shared/enums/subscription-state';
import { FragmentContext } from '../../../shared/types/fragmentContext';
import { AppsBanner } from '../../components/AppsBanner';
import { Divider } from '../../components/Divider';
import { withRoot } from '../../components/Root';
import { useSubscription } from '../../hooks/subscription';
import { useFragmentContext } from '../../hooks/useFragmentContext';
import { Checkout as Layout } from '../../layouts/Checkout';
import { prettyDateShort } from '../../utils/timestamps';
import { cancelSubscription, sessionLogout } from './async';
import { renderSuccessMessage } from './helpers';

// @ts-ignore
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        padding: [0, theme.spacing(2)],
        flex: 1,
        marginTop: -theme.spacing(2),
    },
    welcome: {
        fontSize: 18,
        borderBottom: '1px solid #cccccc',
        paddingBottom: theme.spacing(1.5),
    },
    text: {
        color: '#FFF',
        fontWeight: 400,
        textAlign: 'center',
    },
    submitButton: {
        textTransform: 'none',
        fontSize: 16,
        fontWeight: 600,
        textAlign: 'center',
        margin: '0 auto',
        display: 'inline-block',
        minWidth: 128,
        marginBottom: theme.spacing(1),
    },
    streamNowButton: {
        background: '#22DD22',
        color: '#FFF',
        '&:hover': {
            background: '#36e336',
        },
    },
    submitButtonBackground: {
        background: '#ed532d',
        color: '#FFF',
        '&:hover': {
            background: '#CCC',
        },
    },
    contentWrap: {
        paddingBottom: '0px!important',
    },
    wrapper: {
        textAlign: 'center',
        justifyContent: 'center',
    },
    field: {
        paddingBottom: theme.spacing(),
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        fontSize: 16,
        justifyContent: 'space-between',
        maxWidth: 420,
        margin: '0 auto',
    },
    footer: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: theme.spacing(2),
        '& > actions': {
            flexDirection: 'row',
            margin: '0 auto',
            marginTop: theme.spacing(3),
        },
    },
    actions: {},
    logout: {},
    transparent: {
        background: 'transparent',
    },
    watch: {
        cursor: 'pointer',
        textAlign: 'center',
        marginBottom: theme.spacing(2),
        '& a': {
            color: '#FFF',
        },
    },
    cancelButton: {
        background: '#DDD',
        color: '#ed532d',
        '&:hover': {
            background: '#CCC',
            color: '#ed532d',
        },
    },
}));

type SubscriptionPageProps = {
    id: string;
    name: string;
    logout: () => void;
};

function SubscriptionPage({ id, name, logout }: SubscriptionPageProps): JSX.Element {
    const classes = useStyles();
    const { loaded, subscription } = useSubscription(id);
    const [loading, setLoading] = useState(false);
    const { search } = useLocation();
    const history = useHistory();
    const { plan } = queryString.parse(search);

    const planName = get(subscription, 'plan') || '';
    const isDailyOrWeekly = ['daily', 'weekly'].some(type =>
        planName.toLocaleLowerCase().includes(type),
    );

    const canUpgradePlan = plan && isDailyOrWeekly;

    const redirectUrl =
        subscription?.plan === 'TVOD Chioma'
            ? 'https://play.wi-flix.com/details/movies/65cf846a17ed83001bddabdb'
            : 'https://play.wi-flix.com';

    const isCancelled =
        subscription?.cancelled || subscription?.state === SubscriptionState.CANCELED;

    const isCancellable =
        !isCancelled &&
        !subscription?.freemium &&
        (!!subscription?.extras?.external_subscription || !!subscription?.network);

    const onChangePlan = () => {
        history.push('/change-plan');
    };

    const onCancelSubscription = async () => {
        if (!subscription) {
            return;
        }

        setLoading(true);
        // eslint-disable-next-line promise/prefer-await-to-callbacks
        try {
            await cancelSubscription({
                subscriptionId: subscription.id,
                planId: subscription.plan_id,
            });
            setLoading(false);
            renderSuccessMessage(
                'Subscription cancelled',
                'Your subscription will not renew after your current subscription has expired.',
                () => {
                    window.location.reload();
                },
            );
        } catch (e) {
            setLoading(false);
            // TODO: show error to user
            console.error(e);
        }
    };

    return (
        <div className={classes.root}>
            <h5 className={classnames(classes.welcome, classes.text)}>Welcome {name}</h5>

            {loaded && subscription && (
                <div className={classes.wrapper}>
                    <div className={classes.field}>
                        <span>Current Plan</span>
                        <span>{subscription.plan}</span>
                    </div>
                    {!subscription.extras && (
                        <>
                            <div className={classes.field}>
                                <span>Expires</span>
                                <span>{prettyDateShort(new Date(subscription.expires))}</span>
                            </div>
                        </>
                    )}

                    <>
                        {get(subscription, 'extras.external_subscription.current_period_start') && (
                            <div className={classes.field}>
                                <span>Subscribed</span>
                                <span>
                                    {prettyDateShort(
                                        new Date(
                                            // @ts-ignore
                                            subscription.extras?.external_subscription
                                                .current_period_start * 1000,
                                        ),
                                    )}
                                </span>
                            </div>
                        )}

                        {get(subscription, 'extras.external_subscription.current_period_end') &&
                            !subscription.cancelled && (
                                <div className={classes.field}>
                                    <span>Renews On</span>
                                    <span>
                                        {prettyDateShort(
                                            new Date(
                                                // @ts-ignore
                                                subscription.extras?.external_subscription
                                                    .current_period_end * 1000,
                                            ),
                                        )}
                                    </span>
                                </div>
                            )}

                        {get(subscription, 'extras.external_subscription.current_period_end') &&
                            subscription.cancelled && (
                                <div className={classes.field}>
                                    <span>Expires</span>
                                    <span>
                                        {prettyDateShort(
                                            new Date(
                                                // @ts-ignore
                                                subscription.extras?.external_subscription
                                                    .current_period_end * 1000,
                                            ),
                                        )}
                                    </span>
                                </div>
                            )}

                        {get(subscription, 'extras.external_subscription.card') && (
                            <div className={classes.field}>
                                <span>Credit Card</span>
                                <span>
                                    {/* @ts-ignore */}
                                    {toUpper(subscription.extras?.card?.brand)} ending in{' '}
                                    {/* @ts-ignore */}
                                    {subscription.extras?.card?.last4}
                                </span>
                            </div>
                        )}
                    </>
                </div>
            )}

            {loaded && !subscription && (
                <div className={classes.wrapper}>
                    <Typography className={classes.text} variant="subtitle2" gutterBottom>
                        You don&apos;t have any active subscriptions
                    </Typography>

                    <Link to="/change-plan">
                        <Button variant="contained" className={classes.submitButton}>
                            Subscribe Now
                        </Button>
                    </Link>
                </div>
            )}

            {!loaded && (
                <div className={classes.wrapper}>
                    <Typography className={classes.text} variant="subtitle2" gutterBottom>
                        <CircularProgress size={24} />
                    </Typography>
                </div>
            )}

            <div className={classnames(classes.footer, classes.wrapper)}>
                {loaded && subscription && (
                    <>
                        <Typography className={classes.watch} variant="h3">
                            <RouterLink href={redirectUrl} target="_self">
                                <Button
                                    disabled={loading}
                                    variant="contained"
                                    className={classnames(
                                        classes.submitButton,
                                        classes.streamNowButton,
                                    )}
                                >
                                    Stream Now
                                </Button>
                            </RouterLink>
                        </Typography>
                        {canUpgradePlan && (
                            <Button
                                disabled={loading}
                                variant="contained"
                                onClick={onChangePlan}
                                className={classnames(classes.submitButton)}
                            >
                                Change Plan
                            </Button>
                        )}
                        <div className={classes.actions}>
                            <>
                                {isCancellable && (
                                    <Button
                                        disabled={loading}
                                        variant="contained"
                                        onClick={() => onCancelSubscription()}
                                        className={classnames(
                                            classes.submitButton,
                                            classes.cancelButton,
                                        )}
                                    >
                                        Cancel Subscription
                                    </Button>
                                )}

                                <Divider classes={{ small: classes.transparent }} />
                            </>

                            <Button
                                onClick={logout}
                                variant="contained"
                                className={classnames(
                                    classes.submitButton,
                                    classes.submitButtonBackground,
                                )}
                            >
                                Logout
                            </Button>
                        </div>
                    </>
                )}

                <AppsBanner />
            </div>
        </div>
    );
}

SubscriptionPage.propTypes = {
    logout: PropTypes.func.isRequired,
};

const Subscription = (props: RouteComponentProps<any>): JSX.Element | null => {
    const { user } = useFragmentContext<FragmentContext>();

    const classes = useStyles();

    const logout = () => {
        sessionLogout(() => {
            window.location.href = '/login';
        });
    };

    useEffect(() => {
        if (!user) {
            props.history.push('/login');
        }

        if (user && !user.subscription) {
            props.history.push('/');
        }
    }, [user, props.history]);

    if (!user?.subscription) {
        return null;
    }

    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Layout classes={{ contentWrap: classes.contentWrap }} {...props}>
            <SubscriptionPage
                logout={logout}
                name={`${user.first_name || ''} ${user.last_name || ''}`}
                id={user.subscription}
            />
        </Layout>
    );
};

Subscription.getInitialProps = async () => {
    await Promise.resolve();
};

Subscription.getChunkName = () => {
    return 'Subscription';
};

const SubscriptionRoute = withRoot(Subscription);

export { SubscriptionRoute as Subscription };
