import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import queryString from 'query-string';
import { useEffect, useState } from 'react';

import { withRoot } from '../../components/Root';
import { Checkout as Layout } from '../../layouts/Checkout';

const useStyles = makeStyles(theme => ({
    section: {
        flex: 1,
        padding: [0, theme.spacing(1)],
    },
    base: {
        marginBottom: theme.spacing(3),
    },
    title: {
        fontSize: 18,
        color: '#ed532d',
    },
    message: {
        fontSize: 16,
    },
    voucher: {
        marginTop: '5px',
        fontSize: 13,
        '& a': {
            color: 'coral',
            textDecoration: 'underline',
        },
    },
}));

const statusTypes = {
    EXPIRED: 'EXPIRED',
    USED_UP: 'USED_UP',
    INVALID: 'INVALID',
    VALID: 'VALID',
    REDEEM_FAILED: 'REDEEM_FAILED',
    USED: 'USED',
};

function RedeemPage({ location }) {
    const classes = useStyles();

    const search = queryString.parse(location.search);
    const [message, setMessage] = useState('');

    useEffect(() => {
        switch (search.status) {
            case statusTypes.INVALID:
                setMessage('Sorry the Voucher is Invalid');
                break;
            case statusTypes.EXPIRED:
                setMessage('Sorry, this Voucher is expired');
                break;
            case statusTypes.USED_UP:
                setMessage('Sorry, this Voucher is used up');
                break;
            case statusTypes.USED:
                setMessage('You can redeem this Voucher only once');
                break;
            case statusTypes.REDEEM_FAILED:
                setMessage('Sorry, we could not redeem the Voucher at this time. Please try again');
                break;
            case statusTypes.VALID:
                // TODO: implement handler for valid voucher
                setMessage('Voucher is successfully redeemed');
                break;
            default:
                setMessage('There was an error');
        }
    }, [search.status]);

    return (
        <Layout>
            <div className={classes.section}>
                <div className={classes.base}>
                    <h4 className={classes.title}>Message</h4>
                    <p className={classes.message}>{message}</p>
                    <p className={classes.voucher}>
                        Voucher: <a href={`/vouchers?code=${search.voucher}`}>{search.voucher}</a>
                    </p>
                </div>
                <div>
                    <a href="https://play.wi-flix.com">
                        <Button
                            fullWidth
                            variant="outlined"
                            style={{
                                borderColor: '#ed532d',
                                color: '#ed532d',
                                margin: 'auto',
                                height: '50%',
                            }}
                        >
                            Go Home
                        </Button>
                    </a>
                </div>
            </div>
        </Layout>
    );
}

RedeemPage.getInitialProps = async () => {
    await Promise.resolve();
};

RedeemPage.getChunkName = () => {
    return 'Redeem';
};

const RedeemPageRoute = withRoot(RedeemPage);

export { RedeemPageRoute as RedeemPage };
