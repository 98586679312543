import { Box, styled, Typography } from '@mui/material';
import { Link, RouteComponentProps } from 'react-router-dom';

import { TitleAndMetaTags } from '../../components/TitleAndMetaTags';
import { Markdown } from '../../components/Markdown';
import { withRoot } from '../../components/Root';
import { Container } from '../../components/Container';

const StyledLink = styled(Link)(() => ({
    fontSize: 18,
}));

const PageNotFound = ({ history }: RouteComponentProps<unknown>): JSX.Element => (
    <Box
        sx={{
            background: 'background.paper',
            flex: 1,
        }}
    >
        <Container>
            <Typography variant="h5">Page Not Found</Typography>
            <TitleAndMetaTags title="Wi-Flix - Page Not Found" />
            <Markdown>
                <p>Oops! Looks like you got lost.</p>
            </Markdown>
            <StyledLink to="#" onClick={() => history.go(-1)}>
                Go Back
            </StyledLink>
        </Container>
    </Box>
);

PageNotFound.getInitialProps = async () => {
    await Promise.resolve();
};

PageNotFound.getChunkName = () => {
    return 'PageNotFound';
};

const PageNotFoundRoute = withRoot(PageNotFound);

export { PageNotFoundRoute };
