import './reducer';

export const loadPackages = (promotional = '') => {
    let url = '/packages';
    if (promotional) {
        url = `${url}?promotional=${promotional}`;
    }

    return {
        type: 'LOAD_PACKAGES',
        promise: client => client.GET(url),
    };
};
