import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadPackages } from './actions';

const usePackages = (promotional = '') => {
    const dispatch = useDispatch();
    const { loaded, loading, packages } = useSelector(state => state.PackagesReducer);

    useEffect(() => {
        if (!loading && !loaded) {
            dispatch(loadPackages(promotional));
        }
    }, [loading, loaded, promotional, dispatch]);

    return { loading, loaded, packages: (packages || {}).data };
};

export { usePackages };
