import 'isomorphic-fetch';

const methods = ['GET', 'POST', 'PUT', 'PATCH', 'OPTIONS', 'DELETE'];

function fixPath(path) {
    return path.startsWith('/') ? path : `/${path}`;
}

function formatUrl(uri, config) {
    try {
        const url = new URL(uri);
        return url.toString();
    } catch (e) {
        // empty block
    }

    return `${config.apiPath ?? ''}${fixPath(uri)}`;
}

class ApiClient {
    constructor(config) {
        methods.forEach(method => {
            this[method] = (path, { params, data, contentType, headers = {} } = {}) => {
                const defaultHeaders = {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                };

                if (contentType && contentType === 'form') {
                    delete defaultHeaders['Content-Type'];
                }

                if (!data) {
                    delete defaultHeaders['Content-Type'];
                }

                return fetch(formatUrl(path, config), {
                    method,
                    credentials: 'include',
                    headers: { ...defaultHeaders, ...headers },
                    body: contentType !== 'form' ? JSON.stringify(data) : data,
                    ...params,
                });
            };
        });
    }
}

export { ApiClient, formatUrl };
