import ButtonBase from '@mui/material/ButtonBase';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { useFragmentContext } from '../../hooks/useFragmentContext';
import MTNGhanaLogo from '../../images/mtn_ghana.png';
import VodafoneGhanaLogo from '../../images/vodafone_ghana.png';
import { Network } from './Network';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1',
        padding: theme.spacing(2),
    },
    networkWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: theme.spacing(),
        flexWrap: 'wrap',
    },
    other: {
        position: 'relative',
        padding: theme.spacing(3),
        fontSize: 20,
        fontWeight: 500,
        width: '45%',
        textTransform: 'none',
        background: '#808080',
        color: '#FFF',
        margin: theme.spacing(),
        borderRadius: 0,
        height: 128,
        [theme.breakpoints.down('lg')]: {
            width: '43%',
            height: 100,
        },
        '&:hover, &$focusVisible': {
            zIndex: 1,
            '& $imageBackdrop': {
                opacity: 0.15,
            },
        },
    },
    focusVisible: {},
    imageBackdrop: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: theme.palette.common.black,
        opacity: 0,
        transition: theme.transitions.create('opacity'),
    },
}));

const Networks = {
    MTN: 'MTN',
    Vodafone: 'Vodafone',
    MTNN: 'MTNN',
    Other: 'Other',
};

const networks = [
    {
        name: Networks.MTN,
        imageSrc: MTNGhanaLogo,
        countryCode: 'GH',
    },
    {
        name: Networks.Vodafone,
        imageSrc: VodafoneGhanaLogo,
        countryCode: 'GH',
    },
    {
        name: Networks.MTNN,
        imageSrc: MTNGhanaLogo,
        countryCode: 'NG',
    },
];

const createCountryCodeFilter = countryCode => network =>
    network.countryCode.toLocaleLowerCase() === countryCode.toLocaleLowerCase();

function NetworkPicker(props) {
    const classes = useStyles();

    const { countryCode } = useFragmentContext();
    const countryCodeFilter = createCountryCodeFilter(countryCode);

    return (
        <div className={classes.root}>
            <div className={classes.networkWrapper}>
                {networks.filter(countryCodeFilter).map((network, index) => (
                    <Network
                        onClick={() => props.onSelectNetwork(network.name)}
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        name={network.name}
                        imageSrc={network.imageSrc}
                    />
                ))}

                <ButtonBase
                    onClick={() => props.onSelectNetwork(Networks.Other)}
                    className={classes.other}
                    focusRipple
                    focusVisibleClassName={classes.focusVisible}
                >
                    <span className={classes.imageBackdrop} />
                    Other
                </ButtonBase>
            </div>
        </div>
    );
}

NetworkPicker.propTypes = {
    onSelectNetwork: PropTypes.func.isRequired,
};

NetworkPicker.defaultProps = {
    // eslint-disable-next-line react/default-props-match-prop-types
    countryCode: undefined,
};

export { NetworkPicker };
