import Divider from '@mui/material/Divider';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const styles = theme => ({
    large: {
        margin: `${theme.spacing(2.5)} 0`,
    },
    small: {
        margin: `${theme.spacing(1.2)} 0`,
    },
});

function CustomDivider({ classes, gap }) {
    return <Divider classes={{ root: classes[gap] }} />;
}

CustomDivider.propTypes = {
    gap: PropTypes.oneOf(['small', 'large']),
};

CustomDivider.defaultProps = {
    gap: 'large',
};

const WithStyles = withStyles(styles)(CustomDivider);

export { WithStyles as Divider };
