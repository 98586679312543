import { styled } from '@mui/material';

const Markdown = styled('div')(({ theme }) => ({
    lineHeight: '25px',

    '& > p:first-child': {
        fontSize: 18,
        fontWeight: 300,
        color: 'text.primary',

        [theme.breakpoints.up('lg')]: {
            fontSize: 24,
        },

        '& a, & strong': {
            fontWeight: 400,
        },
    },

    '& > h2': {
        display: 'inline-block',
        color: 'text.primary',
        paddingTop: 0,
        fontSize: 20,
        lineHeight: 1.1,
        '&&': {
            paddingTop: 0,
            fontWeight: 400,
            fontSize: 20,
            [theme.breakpoints.up('xl')]: {
                fontSize: 24,
                fontWeight: 500,
            },
        },
    },

    '& > h3': {
        color: 'text.primary',
        fontWeight: 500,
        fontSize: 32,
        lineHeight: 1.5,
    },

    '& p': {
        lineHeight: '1.75',
        fontWeight: 400,
        color: '#555555',
        marginBottom: 0,
        fontSize: 18,
        // maxWidth: "42em",

        '&:first-of-type': {
            marginTop: 15,
        },

        '&:first-child': {
            marginTop: 0,
        },

        [theme.breakpoints.down('lg')]: {
            fontSize: 16,
            marginTop: 25,
        },
    },

    '& h3 + p, & h3 + p:first-of-type': {
        marginTop: 20,
    },

    '& p > code, & li > code': {
        background: 'rgba(255, 229, 100, 0.3)',
        padding: '0 3px',
        fontSize: 'inherit',
        color: '#333333',
        wordBreak: 'break-word',
    },

    '& hr': {
        height: 1,
        marginBottom: -1,
        border: 'none',
        borderBottom: `1px solid #ececec`,
        marginTop: 40,

        '&:first-child': {
            marginTop: 0,
        },
    },

    '& h1': {
        lineHeight: 1.2,

        [theme.breakpoints.only('xs')]: {
            fontSize: 30,
        },

        [theme.breakpoints.between('sm', 'lg')]: {
            fontSize: 45,
        },

        [theme.breakpoints.up('xl')]: {
            fontSize: 60,
        },
    },

    '& h2': {
        borderTop: `1px solid #ececec`,
        marginTop: 44,
        paddingTop: 40,
        lineHeight: 1.2,
        fontWeight: 500,

        '&:first-child': {
            borderTop: 0,
            marginTop: 0,
            paddingTop: 0,
        },

        [theme.breakpoints.down('lg')]: {
            fontSize: 20,
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: 35,
        },
    },

    '& hr + h2': {
        borderTop: 0,
        marginTop: 0,
    },

    '& h3': {
        paddingTop: 45,

        [theme.breakpoints.up('xl')]: {
            fontSize: 25,
            lineHeight: 1.3,
        },
    },

    '& h2 + h3, & h2 + h3:first-of-type': {
        paddingTop: 30,
    },

    '& h4': {
        fontSize: 20,
        color: 'text.primary',
        lineHeight: 1.3,
        marginTop: 50,
        fontWeight: 400,
    },

    '& h4 + p': {
        marginTop: 20,
    },

    '& ol, & ul': {
        marginTop: 20,
        fontSize: 16,
        color: 'text.primary',
        paddingLeft: 20,

        '& p, & p:first-of-type': {
            fontSize: 16,
            marginTop: 0,
            lineHeight: 1.2,
        },

        '& li': {
            marginTop: 20,
        },

        '& li.button-newapp': {
            marginTop: 0,
        },

        '& ol, & ul': {
            marginLeft: 20,
        },
    },

    '& img': {
        maxWidth: '100%',
    },

    '& ol': {
        listStyle: 'decimal',
    },

    '& ul': {
        listStyle: 'disc',
    },

    '& blockquote': {
        backgroundColor: 'rgba(255, 229, 100, 0.3)',
        borderLeftColor: '#ffe564',
        borderLeftWidth: 9,
        borderLeftStyle: 'solid',
        padding: '20px 45px 20px 26px',
        marginBottom: 30,
        marginTop: 20,
        marginLeft: -30,
        marginRight: -30,

        [theme.breakpoints.down('sm')]: {
            marginLeft: -20,
            marginRight: -20,
        },

        '& p': {
            marginTop: 15,

            '&:first-of-type': {
                fontWeight: 700,
                marginTop: 0,
            },

            '&:nth-of-type(2)': {
                marginTop: 0,
            },
        },
    },

    '& .gatsby-highlight + blockquote': {
        marginTop: 40,
    },

    'b, strong': {
        fontWeight: 600,
    },
}));

export { Markdown };
