import { isAxiosError } from 'axios';

export const getErrorContent = err => {
    let errValue = err;
    if (Array.isArray(err)) {
        const errStrings = err.map(e => e.errorMessage || e.message).filter(Boolean);
        return errStrings.join('\n');
    }

    if (typeof err !== 'string') {
        if (isAxiosError(err)) {
            if (Array.isArray(err.response?.data?.errors))
                return getErrorContent(err.response?.data?.errors);
            return err.response?.data?.message || err.message;
        }

        if (Object.hasOwnProperty.call(err, 'message')) {
            errValue = err.message;
        } else if (Object.hasOwnProperty.call(err, 'error')) {
            return getErrorContent(err.error);
        } else {
            errValue = JSON.stringify(err);
        }
    }

    return errValue;
};
