/* eslint-disable react/prop-types */
import { withStyles } from '@mui/styles';

const styles = theme => ({
    root: {
        zoom: 1,
        width: '100%',
        position: 'relative',
    },
    container: {
        display: 'flex',
        alignItems: 'center!important',
        justifyContent: 'center!important',
        minHeight: '100vh!important',
        [theme.breakpoints.up('md')]: {
            alignItems: 'self-start!important',
            paddingTop: '10rem',
        },
    },
    title: {
        color: '#FFF',
        lineHeight: '1.625!important',
        fontSize: '1.8rem',
        marginBottom: 0,
        marginTop: 0,
        fontWeight: 600,
    },
    background: {
        background: '#000000',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: -1,
    },
    box: {
        border: 0,
        boxShadow: '0 0 0 1px rgba(0,0,0,.08), 0 1px 4px rgba(0,0,0,.1)',
        display: 'flex',
        width: '100%',
        color: '#FFF',
        padding: '4rem!important',
        background: '#222222',
        minWidth: '80vw',
        [theme.breakpoints.up('sm')]: {
            minWidth: '48rem!important',
            width: '32rem!important',
        },
    },
    boxModal: {
        boxShadow: '0 1px 12px rgba(0,0,0,.15)',
        borderColor: '#cbc3d3',
    },
    boxContent: {
        position: 'relative',
        '& $contentWrap': {
            // display: "flex",
            flex: 1,
        },
    },
    contentWrap: {},
    orgLogin: {
        flex: 1,
    },
});

const Checkout = withStyles(styles)(({ children, classes }) => (
    <div className={classes.root}>
        <div className={classes.container}>
            <div className="content">
                <div className={classes.background} />
                <div className={`${classes.box} ${classes.boxModal}`}>
                    <section className={classes.orgLogin}>
                        <div className={classes.boxContent}>
                            <div className={classes.contentWrap}>{children}</div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
));

export { Checkout };
