import { styled } from '@mui/material';

const Container = styled('div')(({ theme }) => ({
    marginRight: 'auto',
    marginLeft: 'auto',

    position: 'relative',
    paddingLeft: 10,
    paddingRight: 10,

    [theme.breakpoints.up('xs')]: {
        width: 740,
    },

    [theme.breakpoints.up('lg')]: {
        width: 960,
    },

    [theme.breakpoints.up('xl')]: {
        width: 1200,
    },

    '&:before': {
        content: '" "',
        display: 'table',
    },
    '&:after': {
        clear: 'both',
    },
}));

export { Container };
