import { Context, createContext } from 'react';

import { FragmentContext } from './types/fragmentContext';
import { Country } from './enums/country';

const createFragmentContext = (): Context<FragmentContext> => {
    return createContext<FragmentContext>({
        countryCode: Country.UNDEFINED,
    });
};

export const fragmentContext = createFragmentContext();
