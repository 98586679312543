import { Link, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import ErrorSVG from '../../images/bug.svg';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3),
        paddingTop: '10vh',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        background: '#FFF',
    },
    imageContainer: {
        marginTop: theme.spacing(6),
        display: 'flex',
        justifyContent: 'center',
    },
    image: {
        maxWidth: '100%',
        width: 560,
        maxHeight: 300,
        height: 'auto',
    },
    buttonContainer: {
        marginTop: theme.spacing(6),
        display: 'flex',
        justifyContent: 'center',
    },
    content: {
        marginTop: theme.spacing(6),
        display: 'flex',
        justifyContent: 'center',
    },
}));

const Error500 = ({ error, children }) => {
    const classes = useStyles();
    if (error) {
        console.error(error);
    }

    return (
        <div className={classes.root}>
            <Helmet title="Something went wrong" />
            <Typography align="center" variant="h4">
                Ooops, something went wrong!
            </Typography>
            <Typography align="center" variant="subtitle1">
                It&apos;s us, not you. We&apos;re investigating this issue.
            </Typography>
            <div className={classes.imageContainer}>
                <img
                    alt="We're investigating this issue."
                    className={classes.image}
                    src={ErrorSVG}
                />
            </div>
            {children && <div className={classes.content}>{children}</div>}
            <div className={classes.buttonContainer}>
                <Button component={Link} href="/">
                    Go to my Dashboard
                </Button>
            </div>
        </div>
    );
};

Error500.propTypes = {
    error: PropTypes.string.isRequired,
    children: PropTypes.node,
};

Error500.defaultProps = {
    children: undefined,
};

export { Error500 };
