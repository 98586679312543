import { doc, getFirestore, onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { Subscription } from '../../../shared/types/subscription';
import { firebaseApp } from '../../firebase-app';

const firestore = getFirestore(firebaseApp);

type UseSubscriptionReturn = {
    loaded: boolean;
    subscription?: Subscription;
};

function useSubscription(id: string): UseSubscriptionReturn {
    const [loaded, setLoaded] = useState(false);
    const [subscription, setSubscription] = useState<Subscription | undefined>(undefined);

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (id) {
            const subscriptionDoc = doc(firestore, 'subscriptions', id);
            const unsubscribe = onSnapshot(subscriptionDoc, subscriptionSnapshot => {
                setLoaded(true);
                if (subscriptionSnapshot.exists()) {
                    setSubscription(subscriptionSnapshot.data() as Subscription);
                }
            });

            // Cleanup the listener when the component unmounts or id changes
            return () => unsubscribe();
        }
    }, [id, loaded]);

    return {
        loaded,
        subscription,
    };
}

export { useSubscription };
