import { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import FormHelperText from '@mui/material/FormHelperText';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        padding: '2rem!important',
        [theme.breakpoints.up('lg')]: {
            fontSize: '1.8rem!important',
            padding: '6.4rem!important',
            lineHeight: '3.2rem!important',
            gap: '2rem!important',
        },
    },
    title: {
        color: '#FFF',
        lineHeight: '1.625!important',
        fontSize: '1.8rem',
        marginBottom: 0,
        marginTop: 0,
        fontWeight: 600,
        paddingBottom: '2rem',
    },
    spinner: {
        minHeight: 60,
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: '1.4rem!important',
        color: '#FFF',
        gap: '2rem!important',
        [theme.breakpoints.up('lg')]: {
            gap: '4rem!important',
        },
    },
    actions: {
        display: 'flex',
        gap: '1rem!important',
        alignItems: 'flex-end',
        flexDirection: 'column',
    },
    btn: {
        paddingLeft: '2.4rem!important',
        paddingRight: '2.4rem!important',
        paddingTop: '1.2rem!important',
        paddingBottom: '1.2rem!important',
        fontSize: '1.6rem!important',
        fontWeight: 600,
        '&:hover': {
            background: theme.palette.secondary.main,
        },
    },
    disabledBtn: {
        backgroundColor: `${theme.palette.secondary.main}!important`,
        color: '#FFF!important',
    },
    error: {
        fontSize: '1.6rem!important',
    },
}));

function PaymentForm({ orderId }) {
    const classes = useStyles();
    const [isSubmitting, setSubmitting] = useState(false);
    const [submitError, setSubmitError] = useState('');
    const elements = useElements();
    const stripe = useStripe();

    const handleSubmit = evt => {
        evt.preventDefault();
        setSubmitting(true);
        const { error } = stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `https://checkout.wi-flix.com/subscription/status?order_id=${orderId}`,
            },
        });

        setSubmitting(false);
        if (error) {
            setSubmitError(error.message);
        }
    };

    return (
        <div className={classes.root}>
            <div className={classes.title}>Complete Subscription</div>
            <form noValidate onSubmit={handleSubmit} className={classes.form}>
                <PaymentElement />

                {Boolean(submitError) && (
                    <FormHelperText error classes={{ error: classes.error }}>
                        {submitError}
                    </FormHelperText>
                )}

                {Boolean(stripe) && (
                    <div className={classes.actions}>
                        <Button
                            disabled={isSubmitting}
                            type="submit"
                            variant="contained"
                            className={classes.btn}
                            classes={{ disabled: classes.disabledBtn }}
                            color="secondary"
                        >
                            Submit
                        </Button>
                    </div>
                )}
            </form>
        </div>
    );
}

PaymentForm.propTypes = {
    orderId: PropTypes.string.isRequired,
};

export { PaymentForm };
