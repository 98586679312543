import { Helmet } from 'react-helmet';

const defaultDescription = '';

type TitleAndMetaTagsProps = {
    title: string;
    ogDescription?: string;
    ogUrl?: string;
};

const TitleAndMetaTags = ({
    title,
    ogDescription = '',
    ogUrl,
}: TitleAndMetaTagsProps): JSX.Element => (
    <Helmet title={title}>
        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        {ogUrl && <meta property="og:url" content={ogUrl} />}
        <meta property="og:image" content="/logo-og.png" />
        <meta property="og:description" content={ogDescription || defaultDescription} />
    </Helmet>
);

export { TitleAndMetaTags };
