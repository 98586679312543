import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getPaymentsConfig } from './actions';

function usePaymentConfig() {
    const dispatch = useDispatch();
    const {
        config: { loading, loaded, data },
    } = useSelector(state => state.PaymentsReducer);

    useEffect(() => {
        if (!loading && !loaded) {
            dispatch(getPaymentsConfig());
        }
    }, [loading, loaded, dispatch]);

    return { loading, loaded, config: data };
}

export { usePaymentConfig };
