import axios from 'axios';

import Axios from 'axios';
import { CancelSubscriptionRequest } from '../../../shared/types/cancelSubscription';
import { CheckoutResponse } from '../../../shared/types/checkout';
import { HttpResponse } from '../../../shared/types/http-response';
import { async } from '../../utils/async';
import { getErrorContent } from '../../utils/errors';

export const cancelSubscription = async (payload: CancelSubscriptionRequest): Promise<void> => {
    try {
        await axios.post('/subscriptions/v1/cancel', payload);
    } catch (e) {
        if (Axios.isAxiosError(e)) {
            const response = e.response?.data as HttpResponse<CheckoutResponse>;
            console.error(response);

            if (!response.success) {
                throw new Error(
                    getErrorContent(response.errors) || 'Failed to cancel subscription',
                );
            }

            throw new Error(response?.message || 'Failed to cancel subscription');
        }

        console.error(e);
        throw new Error('Failed to cancel subscription');
    }
};

// eslint-disable-next-line promise/prefer-await-to-callbacks
export const sessionLogout = (callback: () => void) => {
    async(
        'POST',
        {
            // @ts-ignore
            credentials: 'include',
            headers: {
                Accept: 'application/json',
            },
        },
        '/sessionLogout',
        null,
        callback,
    );
};
