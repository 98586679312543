import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

import { Checkout as Layout } from '../../layouts/Checkout';
import { withRoot } from '../../components/Root';

const useStyles = makeStyles(theme => ({
    root: {
        padding: [0, theme.spacing()],
        margin: theme.spacing(2),
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        borderRadius: 0,
        borderColor: theme.palette.success,
        borderWidth: 2,
    },
    title: {
        fontSize: '18px !important',
        marginBottom: 0,
        textAlign: 'center',
    },
    formActions: {
        marginTop: theme.spacing(3),
        background: 'none',
    },
    btnWrapper: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        '& button': {
            flex: 1,
            marginRight: theme.spacing(3),
            '&:last-of-type': {
                marginRight: 0,
            },
        },
    },
    submitButton: {
        textTransform: 'none',
        background: '#ed532d',
        fontSize: 16,
        fontWeight: 600,
        color: '#FFF',
    },
}));

function PasswordResetSuccess() {
    const classes = useStyles();

    return (
        <Layout>
            <div className={classes.root}>
                <h3 className={classes.title}>Your password was successfully changed.</h3>
                <fieldset className={classes.formActions}>
                    <div className={classes.btnWrapper}>
                        <Button
                            component={Link}
                            to="/"
                            classes={{
                                root: classes.submitButton,
                            }}
                            fullWidth
                            variant="contained"
                        >
                            Ok
                        </Button>
                    </div>
                </fieldset>
            </div>
        </Layout>
    );
}

PasswordResetSuccess.getInitialProps = async () => {
    await Promise.resolve();
};

PasswordResetSuccess.getChunkName = () => {
    return 'PasswordResetSuccess';
};

const PasswordResetSuccessRoute = withRoot(PasswordResetSuccess);

export { PasswordResetSuccessRoute as PasswordResetSuccess };
