import { AsyncRouteComponentType } from 'wi-flix-after/src/types';

import { Checkout } from './pages/Checkout';
import { CheckoutSuccess } from './pages/CheckoutSuccess';
import { CompleteSubscription } from './pages/CompleteSubscription';
import { createRenderFunc } from './pages/createRenderFunc';
import { createRenderFuncWithSub } from './pages/createRenderFuncWithSub';
import { CreateSubscription } from './pages/CreateSubscription';
import { ForgotPassword } from './pages/ForgotPassword';
import { Login } from './pages/Login';
import { MobileMoneyCheckout } from './pages/MobileMoneyCheckout';
import { PageNotFoundRoute } from './pages/PageNotFound';
import { PasswordResetSuccess } from './pages/PasswordResetSuccess';
import { ResetPassword } from './pages/ResetPassword';
import { SelectNetwork } from './pages/SelectNetwork';
import { Subscription } from './pages/Subscription';
import { SubscriptionStatus } from './pages/SubscriptionStatus';
import { Voucher } from './pages/Voucher';
import { RedeemPage } from './pages/Voucher/RedeemPage';

type AsyncRoute<T = any> = {
    path?: string;
    name?: string;
    exact?: boolean;
    component: AsyncRouteComponentType<T>;
};

export const routes: AsyncRoute[] = [
    {
        path: '/vouchers',
        name: 'Voucher',
        exact: true,
        component: Voucher,
    },
    {
        path: '/vouchers/redeem',
        name: 'VoucherRedeem',
        exact: true,
        component: RedeemPage,
    },
    {
        path: '/login',
        exact: true,
        name: 'Login',
        component: createRenderFunc(Login),
    },
    {
        path: '/forgot-password',
        name: 'Forgot Password',
        exact: true,
        component: ForgotPassword,
    },
    {
        path: '/reset-password',
        name: 'Reset Password',
        exact: true,
        component: ResetPassword,
    },
    {
        path: '/password-reset-success',
        name: 'Password Reset',
        exact: true,
        component: PasswordResetSuccess,
    },
    {
        path: '/subscription',
        name: 'Subscription',
        exact: true,
        component: Subscription,
    },
    {
        path: '/checkout-success',
        name: 'Checkout Success',
        exact: true,
        component: CheckoutSuccess,
    },
    {
        path: '/select-network',
        name: 'Select Network',
        exact: true,
        component: createRenderFunc(SelectNetwork, true),
    },
    {
        path: '/',
        exact: true,
        name: 'Checkout',
        component: createRenderFunc(Checkout, true),
    },
    {
        path: '/change-plan',
        exact: true,
        name: 'Checkout',
        component: createRenderFuncWithSub(Checkout),
    },
    {
        path: '/mobile-money-checkout',
        exact: true,
        name: 'Mobile Money Checkout',
        component: createRenderFunc(MobileMoneyCheckout, true),
    },
    {
        path: '/upgrade-to-tvod',
        exact: true,
        name: 'Upgrade to TVOD',
        component: createRenderFuncWithSub(MobileMoneyCheckout),
    },
    {
        path: '/subscription/create',
        exact: true,
        name: 'Create Subscription',
        component: createRenderFuncWithSub(CreateSubscription),
    },
    {
        path: '/subscription/complete',
        exact: true,
        name: 'Complete Subscription',
        component: createRenderFuncWithSub(CompleteSubscription),
    },
    {
        path: '/subscription/status',
        exact: true,
        name: 'Subscription Status',
        component: createRenderFunc(SubscriptionStatus, true),
    },
    { component: PageNotFoundRoute },
];
