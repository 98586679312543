import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';

import { withRoot } from '../../components/Root';
import { PaymentForm } from './PaymentForm';

const CompleteSubscription = ({ location: { state } }) => {
    const { apiKey, clientSecret, orderId, authorizationUrl } = state ?? {};
    const [stripe, setStripe] = useState(null);

    useEffect(() => {
        async function getStripe() {
            const s = await loadStripe(apiKey);
            setStripe(s);
        }

        if (apiKey && !stripe) {
            getStripe();
        }
    }, [apiKey, stripe]);

    if (authorizationUrl) {
        window.location.replace(authorizationUrl);
    }

    if (!apiKey && !clientSecret) {
        return null;
    }

    return (
        <Elements stripe={stripe} options={{ clientSecret, appearance: { theme: 'night' } }}>
            <PaymentForm orderId={orderId} />
        </Elements>
    );
};

CompleteSubscription.getInitialProps = async () => {
    await Promise.resolve();
};

CompleteSubscription.getChunkName = () => {
    return 'CompleteSubscription';
};

const CompleteSubscriptionRoute = withRoot(CompleteSubscription);

export { CompleteSubscriptionRoute as CompleteSubscription };
