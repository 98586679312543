import { combineReducers } from 'redux';

import * as defaultReducers from './index';

const createReducer = (_reducers, initialState = {}) => {
    const reducers = {
        ...defaultReducers,
        ..._reducers,
    };

    const reducerNames = Object.keys(reducers);
    Object.keys(initialState).forEach(item => {
        if (reducerNames.indexOf(item) === -1) {
            reducers[item] = (state = null) => state;
        }
    });

    return combineReducers(reducers);
};

export { createReducer };
