import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import { createReducer } from '../reducers/create';
import { promiseMiddleware as configurePromise } from '../middleware/promise';
import { asyncDispatch } from '../middleware/asyncDispatch';
import { reducerRegistry } from './registry';

export const create = (client, data) => {
    const middleware = [thunk, configurePromise(client), asyncDispatch];
    const createStoreWithFirebase = compose(applyMiddleware(...middleware))(createStore);

    const createReducers = reducers => createReducer(reducers, data);

    const store = createStoreWithFirebase(createReducers(reducerRegistry.getReducers()), data);
    reducerRegistry.setChangeListener(reducers => {
        store.replaceReducer(createReducers(reducers));
    });

    return store;
};
