import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { withStyles } from '@mui/styles';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const styles = theme => ({
    flex: {
        display: 'flex',
        '& [container = ""]': {
            flex: 1,
        },
    },
    formControl: {
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    break: {
        flexBasis: '100%',
        height: 0,
    },
    selectRoot: {
        // minWidth: 128,
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        outline: 'none',
        fontSize: '1.45rem',
        color: 'inherit',
        alignSelf: 'center',
    },
    host: {
        display: 'block',
        margin: 0,
        whiteSpace: 'nowrap',
        paddingTop: theme.spacing(),
    },
    optionsWrapper: {
        flexDirection: 'column',
    },
    menuItem: {
        fontFamily: 'inherit',
        fontSize: '1.45rem',
        lineHeight: '32px',
        position: 'relative',
        whiteSpace: 'nowrap',
        padding: '0 16px',
        display: 'flex',
        alignItems: 'center',
        transition: 'background',
        color: 'rgba(0,0,0,.87)',
        cursor: 'pointer',
        height: 'initial',
    },
    progress: {
        alignSelf: 'center',
        marginLeft: theme.spacing(),
    },
    outlinedIcon: {
        top: 'unset',
    },
    label: {},
    labelFocused: {},
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

function CustomSelect({ classes, ...props }) {
    let errors = [];
    if (typeof props.error === 'string') {
        errors.push(props.error);
    } else if (Array.isArray(props.error)) {
        errors = errors.concat(props.error);
    }

    const hasError = Boolean(errors.length);

    return (
        <FormControl
            fullWidth={props.fullWidth}
            error={hasError}
            classes={{
                root: classnames(classes.formControl, props.flex && classes.flex),
            }}
        >
            {props.label && (
                <InputLabel
                    classes={{
                        root: classes.label,

                        focused: classes.labelFocused,
                    }}
                    id="select-item"
                    htmlFor={props.for}
                >
                    {props.label}
                </InputLabel>
            )}
            <Select
                disabled={props.disabled}
                labelId="select-item"
                multiple={props.multiple}
                variant={props.variant}
                value={props.value}
                displayEmpty
                onChange={props.onChange}
                inputProps={props.inputProps}
                required={props.required}
                classes={{
                    root: classnames(classes.selectRoot, classes.host),

                    selectMenu: classes.optionsWrapper,

                    iconOutlined: classes.outlinedIcon,
                }}
                MenuProps={{
                    PaperProps: {
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                            width: 250,
                        },
                    },
                }}
                container=""
                fullWidth
            >
                {props.options.map((entry, index) => (
                    <MenuItem
                        selected={props.value === entry.value}
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        classes={{
                            root: classes.menuItem,
                        }}
                        value={entry.value}
                    >
                        {entry.label}
                    </MenuItem>
                ))}
            </Select>
            {props.placeholder && <FormHelperText>{props.placeholder}</FormHelperText>}
            {props.loading && (
                <CircularProgress classes={{ root: classes.progress }} color="primary" size={16} />
            )}
            <div className={classes.break} />
            {hasError &&
                // eslint-disable-next-line promise/prefer-await-to-callbacks
                errors.map((err, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <FormHelperText key={index} variant="standard">
                        {err}
                    </FormHelperText>
                ))}
        </FormControl>
    );
}

CustomSelect.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            label: PropTypes.node,
        }),
    ).isRequired,
    inputProps: PropTypes.shape({}),
    for: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onChange: PropTypes.func.isRequired,
    required: PropTypes.bool,
    loading: PropTypes.bool,
    flex: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    fullWidth: PropTypes.bool,
    variant: PropTypes.string,
    multiple: PropTypes.bool,
    disabled: PropTypes.bool,
};

CustomSelect.defaultProps = {
    inputProps: {},
    for: undefined,
    label: undefined,
    placeholder: undefined,
    required: false,
    loading: false,
    flex: false,
    error: undefined,
    fullWidth: false,
    variant: undefined,
    multiple: false,
    disabled: false,
};

const WithStyles = withStyles(styles)(CustomSelect);

export { WithStyles as Select };
