import { ThemeProvider } from '@mui/material/styles';
import { Component, ErrorInfo, ReactNode } from 'react';
import { Ctx } from 'wi-flix-after';
import { AsyncRouteComponentType } from 'wi-flix-after/src/types';

import { theme } from '../../theme';
import { Error500 } from '../ErrorBoundary/Error500';
import { CssReset } from './CssReset';

type RootState = {
    error?: Error;
};

function withRoot<T>(BaseComponent: AsyncRouteComponentType<T>): AsyncRouteComponentType<T> {
    class Root extends Component<T, RootState> {
        constructor(props: T) {
            super(props);
            this.state = { error: undefined };
        }

        componentDidMount(): void {
            // Remove the server-side injected CSS.
            const serverStyles = document.querySelector('#jss-server-side');
            if (serverStyles && serverStyles.parentNode) {
                serverStyles.parentNode.removeChild(serverStyles);
            }
        }

        componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
            // eslint-disable-next-line no-console
            console.log(errorInfo);
            this.setState({ error });
        }

        static getChunkName(): string | undefined {
            return BaseComponent.getChunkName() || 'Root';
        }

        static getInitialProps(props: Ctx<any>): any {
            return BaseComponent.getInitialProps(props);
        }

        render(): ReactNode {
            const { error } = this.state;

            return (
                // eslint-disable-next-line react/jsx-filename-extension
                <ThemeProvider theme={theme}>
                    <CssReset />
                    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                    {error ? <Error500 error={error.message} /> : <BaseComponent {...this.props} />}
                </ThemeProvider>
            );
        }
    }

    return Root;
}

export { withRoot };
