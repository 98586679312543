import { makeStyles } from '@mui/styles';
import queryString from 'query-string';

import { withRoot } from '../../components/Root';
import { Checkout } from '../../layouts/Checkout';
import { NetworkPicker } from './NetworkPicker';

const useStyles = makeStyles(theme => ({
    '@global': {
        '.PhoneInputCountry': {
            marginRight: 8,
            verticalAlign: 'middle',
            fontSize: 18,
        },
    },
    onboardingStep: {
        flex: 1,
        padding: 0,
        '& form': {
            flex: 1,
        },
    },
    title: {
        fontSize: '18px !important',
        marginBottom: '0!important',
        textAlign: 'center',
    },
    actions: {
        marginTop: theme.spacing(3),
        background: 'none',
    },
    btnWrapper: {
        position: 'relative',
    },
    signUpButton: {
        textTransform: 'none',
        background: '#ed532d',
        fontSize: 16,
        color: '#FFF',
    },
    signUpMeta: {
        textAlign: 'center',
        marginTop: theme.spacing(3),
    },
    btnDisabled: {
        backgroundColor: '#cccccc!important',
        color: '#666666!important',
    },
}));

function SelectNetwork(props) {
    const { history } = props;
    const classes = useStyles();

    const onSelectNetwork = (network, countryCode) => {
        const search = {};
        search.nw = network;
        search.country = countryCode;

        history.replace({
            pathname: '/',
            search: queryString.stringify(search),
        });
    };

    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Checkout {...props}>
            {() => {
                return (
                    <section className={classes.onboardingStep}>
                        <h3 className={classes.title}>Select your mobile network</h3>
                        <NetworkPicker
                            onSelectNetwork={onSelectNetwork}
                            countryCode={(props.user || {}).country_code}
                        />
                    </section>
                );
            }}
        </Checkout>
    );
}

SelectNetwork.getInitialProps = async () => {
    await Promise.resolve();
};

SelectNetwork.getChunkName = () => {
    return 'SelectNetwork';
};

const SelectNetworkRoute = withRoot(SelectNetwork);

export { SelectNetworkRoute as SelectNetwork };
