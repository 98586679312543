import { colors } from '@mui/material';

const MuiChip = {
    styleOverrides: {
        root: {
            backgroundColor: colors.blueGrey[50],
            color: colors.blueGrey[900],
        },
        deletable: {
            '&:focus': {
                backgroundColor: colors.blueGrey[100],
            },
        },
    },
};

export { MuiChip };
