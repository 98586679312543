import { renderModalPrompt } from '../../utils/common';
import { Confirm } from '../../components/Confirm';

export const renderSuccessMessage = (title, message, onClose, acceptLabel = 'Ok') => {
    const runClose = () => {
        if (typeof onClose === 'function') {
            onClose();
        }
    };

    renderModalPrompt(
        title,
        {
            title,
            content: message,
            acceptLabel,
            disableCancel: true,
            onAccept: () => runClose(),
        },
        Confirm,
    );
};
