import queryString from 'query-string';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import { Ctx } from 'wi-flix-after';
import { AsyncRouteComponentType } from 'wi-flix-after/src/types';
import { FragmentContext } from '../../shared/types/fragmentContext';
import { withRoot } from '../components/Root';
import { useFragmentContext } from '../hooks/useFragmentContext';

const getReferrer = (pathname: string, query: string): string => {
    const search = queryString.parse(query) || {};
    const { referrer } = search;

    if (referrer) {
        return referrer as string;
    }

    const basePath = pathname;
    if (query) {
        return `${basePath}${encodeURIComponent(query)}`;
    }

    return basePath;
};

const createRenderFuncWithSub = (
    BaseComponent: AsyncRouteComponentType<any>,
): AsyncRouteComponentType<any> => {
    const renderFunc = (props: RouteComponentProps<any>): JSX.Element => {
        const { user } = useFragmentContext<FragmentContext>();
        const isAuthenticated = !!user;
        const { location } = props;

        if (!isAuthenticated) {
            return (
                <Redirect
                    to={{
                        pathname: '/login',
                        search: `?referrer=${getReferrer(location.pathname, location.search)}`,
                    }}
                />
            );
        }

        return <BaseComponent {...props} />;
    };

    renderFunc.getChunkName = () => {
        return BaseComponent.getChunkName() || BaseComponent.name;
    };

    renderFunc.getInitialProps = (props: Ctx<any>): any => {
        return BaseComponent.getInitialProps(props);
    };

    return withRoot(renderFunc);
};

export { createRenderFuncWithSub };
