import { CacheProvider } from '@emotion/react';
import { render } from 'react-dom';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { After, ensureReady } from 'wi-flix-after';

import { createEmotionCache } from '../shared/createEmotionCache';
import { fragmentContext } from '../shared/fragmentContext';
import { App } from './pages';
import { routes } from './routes';
import { create as createStore } from './store/create';
import { ApiClient } from './utils/ApiClient';
import { createAsyncClient } from './utils/async';

const client = new ApiClient({
    apiPath: '/api',
});

const clientSideEmotionCache = createEmotionCache();
const async = createAsyncClient();

ensureReady(routes)
    .then(data => {
        if (process?.env.NODE_ENV === 'production') {
            TagManager.initialize({
                gtmId: 'GTM-T6V3XP8',
            });
        }

        const store = createStore(client, data?.initialState || {});
        const container = document.getElementById('root');
        return render(
            <CacheProvider value={clientSideEmotionCache}>
                <Provider store={store}>
                    <fragmentContext.Provider value={data?.contextValue}>
                        <Router>
                            <App async={async}>
                                <After data={data} routes={routes} />
                            </App>
                        </Router>
                    </fragmentContext.Provider>
                </Provider>
            </CacheProvider>,
            container,
        );
    })
    .catch(err => {
        // eslint-disable-next-line no-console
        console.error(err);
    });

if (module.hot) {
    module.hot.accept();
}
