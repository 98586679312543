import Axios from 'axios';

import { CheckoutRequest, CheckoutResponse } from '../../../shared/types/checkout';
import { HttpResponse } from '../../../shared/types/http-response';
import { async } from '../../utils/async';
import { getErrorContent } from '../../utils/errors';

export const createCheckout = async (
    checkoutRequest: CheckoutRequest,
): Promise<CheckoutResponse | undefined> => {
    try {
        const response = await Axios.post<HttpResponse<CheckoutResponse>>(
            '/checkout/v1',
            checkoutRequest,
            {
                withCredentials: true,
            },
        );

        if (response && response.data?.success && response.data?.body) {
            return response.data.body;
        }
    } catch (e) {
        if (Axios.isAxiosError(e)) {
            const response = e.response?.data as HttpResponse<CheckoutResponse>;

            if (response && !response.success) {
                throw new Error(
                    response?.message ||
                        getErrorContent(response.errors) ||
                        'Failed to create subscription',
                );
            }

            throw new Error(response?.message || 'Failed to create subscription');
        }

        console.error(e);
        throw new Error('Failed to create subscription');
    }
};

// @ts-ignore
export const createPaymentSession = (payload, callback = () => {}) => {
    async(
        'POST',
        {
            // @ts-ignore
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
        },
        '/create-payment-session',
        payload,
        callback,
    );
};
