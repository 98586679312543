import { makeStyles } from '@mui/styles';

import hillCityBackground from '../../images/hillcity.jpg';

const Promotion = {
    HILL_CITY: 'hill-city',
};

const usePromotionalBgImageStyles = makeStyles(theme => ({
    hillCityBackground: {
        backgroundImage: `url(${hillCityBackground})`,
        backgroundPosition: 'center center',
        backgroundSize: '100%',
        backgroundRepeat: 'no-repeat',
        opacity: 0.1,
        [theme.breakpoints.down('xl')]: {
            backgroundSize: 'cover',
        },
    },
}));

const Promotionals = {
    [Promotion.HILL_CITY]: {
        backgroundImageClass: 'hillCityBackground',
    },
};

export { Promotionals, usePromotionalBgImageStyles };
