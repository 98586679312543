import { reducerRegistry } from '../../store/registry';

const defaultState = {
    loading: false,
    loaded: false,
    packages: [],
};

function PackagesReducer(state = defaultState, action) {
    switch (action.type) {
        case 'LOAD_PACKAGES_REQUEST':
            return { loading: true };
        case 'LOAD_PACKAGES':
            return { loading: false, loaded: true, packages: action.body };
        case 'LOAD_PACKAGES_FAILURE':
            console.error(action.error);
            return { loading: false, loaded: true };
        default:
            return state;
    }
}

reducerRegistry.register('PackagesReducer', PackagesReducer);
