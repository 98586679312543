import ReactDOM from 'react-dom';
import q from 'q';

import { Prompt } from '../components/CustomModal';

// eslint-disable-next-line promise/prefer-await-to-callbacks
export const parseResponse = (response, callback) => {
    const { headers } = response;
    const headerContentType = headers.get('Content-Type') || '';
    const headerContentLength = headers.get('Content-Length') || 0;
    let contentType = headerContentType.toLowerCase();
    contentType = contentType ? contentType.replace('; charset=utf-8', '') : '';
    const unknownContentType = new Error('unknown response type');
    const responseOk = response.ok;

    const callCallback = (err, body) => {
        callback(err, body, response.status);
    };

    try {
        if (response.redirected) {
            callCallback(null, { redirect: true, location: response.url });
            return;
        }

        if (response.ok && response.status === 204) {
            callCallback(null, null);
            // return;
        }

        if (response.status === 404) {
            callCallback(new Error('not found'));
            return;
        }

        if (contentType) {
            if (contentType.toLowerCase() === 'application/json') {
                response
                    .json()
                    .then(result => {
                        if (responseOk) {
                            callCallback(null, result);
                            return;
                        }

                        // eslint-disable-next-line promise/always-return
                        callCallback(result || new Error('error'));
                    })
                    .catch(err => {
                        console.error(err);
                        if (response.ok) {
                            callCallback(null);
                            return;
                        }

                        callCallback(new Error('Unknown error'));
                    });
            } else if (
                contentType.toLowerCase() === 'text/html' ||
                contentType.toLowerCase() === 'text/plain'
            ) {
                response
                    .text()
                    .then(result => {
                        if (responseOk) {
                            callCallback(null, result);
                            return;
                        }

                        // eslint-disable-next-line promise/always-return
                        if (result) {
                            callCallback(new Error(result));
                            return;
                        }

                        callCallback(new Error('request error, reason unknown'));
                    })
                    .catch(err => {
                        callCallback(err);
                    });
            } else {
                if (headerContentLength > 0) {
                    callCallback(unknownContentType);
                    return;
                }

                callCallback(null);
            }
        } else {
            if (responseOk) {
                callCallback(null);
                return;
            }

            callCallback(unknownContentType);
        }
    } catch (err) {
        console.error(err);
        callCallback(err);
    }
};

const renderModal =
    Component =>
    (title = '', props, ChildComponent, noModal) => {
        const future = q.defer();
        const wrapper = document.body.appendChild(document.createElement('div'));

        if (noModal) {
            // eslint-disable-next-line react/jsx-props-no-spreading
            ReactDOM.render(<ChildComponent {...props} promise={future} />, wrapper);
        } else {
            ReactDOM.render(
                <Component title={title} promise={future} contentProps={props}>
                    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                    <ChildComponent {...props} />
                </Component>,
                wrapper,
            );
        }

        const cleanup = () => {
            ReactDOM.unmountComponentAtNode(wrapper);
            setTimeout(() => wrapper.remove());
        };

        future.promise.fin(cleanup);
    };

export const renderModalPrompt = (title = '', props, ChildComponent) =>
    renderModal(Prompt)(title, props, ChildComponent);
