/* eslint-disable react/prop-types */
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
    root: {
        cursor: 'pointer',
        position: 'relative',
        minWidth: theme.spacing(26),
        background: 'none',
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingBottom: theme.spacing(2),
    },
    radio: {
        position: 'absolute',
        top: '.8rem!important',
        right: '.8rem!important',
        '& svg': {
            height: '2rem!important',
            width: '2rem!important',
        },
    },
    outlined: {
        border: '1px solid #a3a9b2',
    },
    mainText: {
        flexDirection: 'column',
        gap: '.6rem!important',
        color: '#FFF',
        display: 'flex',
        marginRight: '2.4rem!important',
    },
    price: {
        fontWeight: 600,
        fontSize: '2rem!important',
        gap: '1.2rem!important',
        display: 'flex',
        flexDirection: 'row',
    },
    bundle: {
        fontSize: '1.6rem!important',
        opacity: '.6!important',
    },
    validity: {
        opacity: '.6!important',
        fontSize: '1.6rem!important',
    },
}));

const getValidityDescriptor = days => {
    switch (days) {
        case 1:
            return '1 day';
        case 7:
            return '1 week';
        case 30:
            return '1 month';
        case 365:
            return '1 year';
        default:
            return `${days} days`;
    }
};

function Plan({ selected, plan, onChange }) {
    const classes = useStyles();
    const withData = !!plan.network && !!plan.data_volume && !!plan.data_unit;

    return (
        <Paper
            onClick={() => onChange(plan.id)}
            variant="outlined"
            classes={{ root: classes.root, outlined: classes.outlined }}
        >
            <div className={classes.mainText}>
                <div className={classes.price}>{`${plan.currency} ${plan.cost}`}</div>
                {withData && (
                    <div className={classes.bundle}>{`${plan.data_volume} ${plan.data_unit}`}</div>
                )}
                <div className={classes.validity}>
                    for {getValidityDescriptor(plan.validity)} {plan.auto_renew && '(Auto-Renew)'}
                </div>
                {plan.description && <div className={classes.validity}>{plan.description}</div>}
            </div>
            <div className={classes.radio}>
                <Radio checked={selected} color="secondary" id={plan.id} />
            </div>
        </Paper>
    );
}

Plan.propTypes = {
    selected: PropTypes.bool,
    plan: PropTypes.shape({
        currency: PropTypes.string,
        cost: PropTypes.number,
    }).isRequired,
};

Plan.defaultProps = {
    selected: false,
};

export { Plan };
