import { createTheme } from '@mui/material';

import { palette } from './palette';
import { typography } from './typography';
import {
    MuiButton,
    MuiCardHeader,
    MuiCardContent,
    MuiCardActions,
    MuiChip,
    MuiIconButton,
    MuiInputBase,
    MuiLinearProgress,
    MuiListItemIcon,
    MuiOutlinedInput,
    MuiPaper,
    MuiToggleButton,
    MuiTypography,
    MuiLink,
} from './components';
import { TypographyOptions } from '@mui/material/styles/createTypography';

const theme = createTheme({
    palette,
    typography: typography as TypographyOptions,
    components: {
        MuiButton,
        MuiCardActions,
        MuiCardContent,
        MuiCardHeader,
        MuiChip,
        MuiIconButton,
        MuiInputBase,
        MuiLinearProgress,
        MuiListItemIcon,
        MuiOutlinedInput,
        MuiPaper,
        MuiToggleButton,
        MuiTypography,
        MuiLink,
    },
});

export { theme };
