/* eslint-disable react/prop-types, camelcase */
import { withStyles } from '@mui/styles';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import InputAdornment from '@mui/material/InputAdornment';

import { TextField } from '../TextField/Material';

export const inputStyles = theme => ({
    inputContainer: {
        flexGrow: 1,
        fontSize: 14,
    },
    textFieldRoot: {
        display: 'inline-flex',
        outline: 'none',
        textAlign: 'inherit',
        minWidth: 176,
        lineHeight: 'initial',
    },
    nameInput: {
        minWidth: 320,
    },
    underline: {
        '&:before,&:after': {
            borderBottomColor: 'rgba(0,0,0,.12)',
        },
        '&$inputFocused:before, &$inputFocused:after': {
            borderBottomColor: '#4285f4',
            // borderBottomColor: colors.brandContrast,
        },
        '&:hover:not($inputFocused):not($disabledInput):before': {
            borderBottomColor: 'rgba(0,0,0,.12)!important',
            borderBottomWidth: '1px!important',
        },
        '&$error:after': {
            borderBottomColor: theme.palette.error.main,
        },
    },
    disabledInput: {
        opacity: 0.6,
        // background: "#fcfcfc!important",
        cursor: 'not-allowed',
        '&:focus,&:active': {
            outline: 'none',
            border: 'none',
        },
    },
    inputFocused: {
        '& fieldset': {
            borderColor: 'transparent!important',
        },
    },
    inputAdornmentRoot: {
        marginRight: 8,
    },
    formHelperText: {
        marginTop: 0,
        '&$error': {
            color: '#c53929',
            marginBottom: theme.spacing(),
        },
    },
    error: {},
});

function InputComponent({ classes, value, ...props }) {
    return (
        <div className={classes.inputContainer}>
            <TextField
                name={props.name}
                value={value}
                onChange={props.onChange}
                type={props.type || 'text'}
                error={props.error}
                required={props.required}
                fullWidth={props.fullWidth}
                multiline={props.multiline}
                onBlur={props.onBlur}
                onSubmit={props.onSubmit}
                autoComplete={props.autoComplete}
                rows={4}
                inputProps={{
                    ...(props.inputProps || {}),
                    classes: {
                        root: classnames(classes.nameInput, classes.textFieldRoot),
                        disabled: classes.disabledInput,
                        focused: classes.inputFocused,
                        error: classes.error,
                    },
                    startAdornment: props.startAdornmentText ? (
                        <InputAdornment
                            position="start"
                            classes={{ root: classes.inputAdornmentRoot }}
                        >
                            {props.startAdornmentText}
                        </InputAdornment>
                    ) : (
                        props.startAdornment
                    ),
                    disabled: props.disabled,
                    ...props.inputProps,
                }}
                formHelperTextProps={{
                    variant: 'standard',
                    classes: {
                        root: classes.formHelperText,
                        error: classes.error,
                    },
                }}
                placeholder={props.placeholder}
                variant={props.variant}
            />
        </div>
    );
}

InputComponent.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    name: PropTypes.string,
    startAdornmentText: PropTypes.node,
    startAdornment: PropTypes.node,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    type: PropTypes.string,
    disabled: PropTypes.bool,
    multiline: PropTypes.bool,
    fullWidth: PropTypes.bool,
    onBlur: PropTypes.func,
    onSubmit: PropTypes.func,
    variant: PropTypes.string,
};

InputComponent.defaultProps = {
    variant: 'outlined',
    value: undefined,
    onChange: () => {},
    name: undefined,
    startAdornmentText: undefined,
    startAdornment: undefined,
    error: undefined,
    type: 'text',
    disabled: false,
    multiline: false,
    fullWidth: false,
    onBlur: () => {},
    onSubmit: () => {},
};

const Input = withStyles(inputStyles)(InputComponent);

export { Input };
